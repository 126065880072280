<template>
  <div class="copyId">
    <span class="text--bold"> {{ label }}</span>
    <div
      v-if="itemId"
      class="copyId-wrapper"
      @click.prevent.stop="copyText(itemId, entity)"
    >
      <p class="copyId--id" :class="{ small, extendable }">
        {{ itemId }}
      </p>
      <i class="copyId--icon" />
    </div>

    <p v-else class="copyId--id">
      {{ emptyText }}
    </p>
  </div>
</template>

<script>
import { copyToClipboard, showNotification } from '@/utils/generalUtils'

export default {
  name: 'CopyID',
  props: {
    itemId: {
      type: String,
      required: false
    },
    entity: {
      type: String,
      default: 'ID'
    },
    label: {
      type: String,
      required: false
    },
    emptyText: {
      type: String,
      default: '---'
    },
    small: {
      type: Boolean,
      default: false
    },
    extendable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    copyText (id, entity) {
      if (!id) return

      copyToClipboard(id)
      showNotification(
        this,
        'is-added',
        `Successfully copied the ${entity} ID`
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";

.copyId {
  cursor: pointer;

  &-wrapper {
    width: fit-content;
    @extend .d-flex, .middle-xs;

    &:not(.disabled):hover {
      .copyId--icon {
        opacity: 1;
      }

      .copyId--id  {
        &.extendable {
          max-width: 20rem;
        }
        text-decoration: underline;
        color: $un-blue;
      }
    }
  }

  &--id {
    @extend .text--ellipse, .text--body-2, .text--light;
    transition: max-width 0.1s ease;
    max-width: 12rem;
    overflow: hidden;
    white-space: nowrap;

    &.small{
      max-width: 7rem;
    }
  }

  &--icon {
    transition: opacity 0.2s ease;
    opacity: 0;
    margin-left: 0.5rem;
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%23343941' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24'%3E%3Cpath fill='%233843D0' fill-rule='evenodd' d='M5.25 6c.417 0 .667.25.667.667v13.416c0 .334.25.667.666.667h11.25c.334 0 .667.25.667.667 0 .416-.5.583-.833.583H6.417c-1 0-1.834-.833-1.834-1.833v-13.5c0-.334.25-.667.667-.667zm11.333-4c.584 0 1.084.25 1.5.667l2.75 2.916c.417.417.584.917.584 1.5v10c0 1.167-.917 2.167-2.084 2.167H9.167v.083c-1.167 0-2.084-1-2.084-2.166v-13C7 3 8 2 9.167 2h7.416zm0 1.25H9.167c-.5 0-.834.417-.834.917v13c0 .5.334.916.834.916h10.166c.462 0 .781-.355.828-.803l.006-.113v-10c0-.25-.084-.5-.25-.667l-2.75-3a.9.9 0 00-.584-.25z'/%3E%3C/svg%3E");
    }
  }
}
</style>
