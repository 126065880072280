import { API } from '@/api/api'
import {
  formatDate
} from '@/utils/dateUtils'
import { GLOBAL_ACTIONS, GLOBAL_MUTATIONS, FILTERS_DICT } from '@/data/enum'
import { parseURLparams, updateURLparams } from '@/utils/urlUtils'
import { SEARCH_FILTERS_CONFIG, MANDATORY_URL_PARAM_FILTERS } from '@/data/filters'

function searchForTranslations (state, commit, payload, isSidepanelDataLoading) {
  // loading state
  commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)

  const newFilters = {
    ...state.searchFilters
  }

  // if it's a new search, update filters on state
  if (payload && payload.isNewSearch) {
    Object.keys(payload.filters).forEach(key => {
      newFilters[`${key}`] = payload.filters[`${key}`]
    })
    commit(GLOBAL_MUTATIONS.UPDATE_SEARCH_FILTERS, newFilters)
  }

  const stateFilters = Object.assign({}, state.searchFilters)

  // if the action is "LOAD MORE", then assign WITHOUT writing to state the next_page dates to the "DATE_LESS" and "DATE_GREATER"
  if (payload.isLoadMore) {
    if (stateFilters[`${FILTERS_DICT.NP_DATE_LESS_THAN}`]) {
      stateFilters[`${FILTERS_DICT.DATE_LESS_THAN}`] = stateFilters[`${FILTERS_DICT.NP_DATE_LESS_THAN}`]
    }
    if (stateFilters[`${FILTERS_DICT.NP_DATE_GREATER_OR_EQUAL_THAN}`]) {
      stateFilters[`${FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN}`] = stateFilters[`${FILTERS_DICT.NP_DATE_GREATER_OR_EQUAL_THAN}`]
    }
  }

  // XHR request for search items to Flowrunner
  return API.searchTranslations(stateFilters, {
    isLoadMore: payload.isLoadMore,
    isNewSearch: payload.isNewSearch
  }).then(res => {
    const paramUpdates = {}

    // update "last page" and "next page" params
    paramUpdates[`${FILTERS_DICT.LAST_PAGE}`] = res.last_page

    if (res.next_search_page && res.next_search_page.date_less_than) {
      paramUpdates[`${FILTERS_DICT.NP_DATE_LESS_THAN}`] = res.next_search_page.date_less_than
    }
    if (res.next_search_page && res.next_search_page.date_greater_or_equal_than) {
      paramUpdates[`${FILTERS_DICT.NP_DATE_GREATER_OR_EQUAL_THAN}`] = res.next_search_page.date_greater_or_equal_than
    }

    updateURLparams(payload.ctx, paramUpdates)

    // update filters on state with the new information
    if (Object.keys(paramUpdates).length) {
      const newFilters = {
        ...state.searchFilters
      }

      Object.keys(paramUpdates).forEach(key => {
        newFilters[`${key}`] = paramUpdates[`${key}`]
      })

      commit(GLOBAL_MUTATIONS.UPDATE_SEARCH_FILTERS, newFilters)
    }

    // update refresh time
    const refreshTime = formatDate(new Date(), 'HH:mm', true)
    commit(GLOBAL_MUTATIONS.UPDATE_REFRESH_TIME, refreshTime)

    if (payload.isLoadMore) {
      commit(GLOBAL_MUTATIONS.APPEND_LIST, res.messages)
    } else {
      commit(GLOBAL_MUTATIONS.UPDATE_LIST, res.messages)
    }

    // only hide loader if sidepanel data is not loading...
    if (!isSidepanelDataLoading) {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
    }

    return res
  }, (err) => {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
    return Promise.reject(err)
  })
}

function getMessageDetails (commit, payload) {
  return API.getTranslationDetails(payload, commit).then(res => {
    // update task details on the store
    if (payload.taskId && res.human_translation_details?.tasks) {
      const task = res.human_translation_details.tasks.find(item => item.id === payload.taskId)
      const taskIndex = res.human_translation_details.tasks.findIndex(item => item.id === payload.taskId)

      if (!task) {
        return Promise.reject(new Error(`No task with ${payload.taskId} found when fetching Task Details`))
      }

      // update translation record details on the store
      if (taskIndex >= 0 &&
        payload.translationRecordId &&
        res.human_translation_details?.tasks[taskIndex]?.translation_records) {
        const translationRecord = res.human_translation_details?.tasks[taskIndex]?.translation_records.find(item => item.id === payload.translationRecordId)

        if (!translationRecord) {
          return Promise.reject(new Error(`No translation record with ${payload.translationRecordId} found when fetching Translation Record Details`))
        }

        commit(GLOBAL_MUTATIONS.UPDATE_TRANSLATION_RECORD_DETAILS, translationRecord)
      } else if (payload.translationRecordId && res.human_translation_details?.translationRecordsLoadingError) {
        // if somehow tarkin cannot load translations records, display an error message
        return Promise.reject(new Error(`An issue occured in Tarkin while fetching translation record ${payload.taskId}`))
      }

      commit(GLOBAL_MUTATIONS.UPDATE_TASK_DETAILS, task)
    } else if (payload.taskId && res.human_translation_details?.tasksLoadingError) {
      // if somehow tarkin cannot load tasks, display an error message
      return Promise.reject(new Error(`An issue occured in Tarkin while fetching task ${payload.taskId}`))
    }

    return res
  })
}

const actions = {
  [GLOBAL_ACTIONS.SET_LOADING] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, payload)
  },
  [GLOBAL_ACTIONS.SET_USER_INFO] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_USER, payload)
  },
  [GLOBAL_ACTIONS.INIT_SEARCH_VIEW] ({ state, commit }, ctx) {
    // check is we are loading sidepanel data also
    const isSidepanelDataLoading = this.$route?.query?.pThrId?.length > 0 && this.$route?.query?.pMsgId?.length > 0

    // if list item INDEX param (used in sidepanel next & prev navigation of translations)
    // read it then update store
    if (ctx.$route?.query?.index) {
      const index = parseInt(ctx.$route.query.index)
      commit(GLOBAL_MUTATIONS.UPDATE_LIST_ITEM_INDEX, index)
      delete ctx.$route.query.index
    }

    // read URL params and set filters, if no params then reset all values their defaults
    const newFilters = {
      ...state.searchFilters
    }

    if (Object.keys(ctx.$route?.query).length) {
      const filters = parseURLparams(ctx)
      Object.keys(filters).forEach(key => {
        newFilters[`${key}`] = filters[`${key}`]
      })
    } else {
      Object.keys(newFilters).forEach(key => {
        newFilters[`${key}`] = SEARCH_FILTERS_CONFIG[`${key}`].value
      })
    }

    commit(GLOBAL_MUTATIONS.UPDATE_SEARCH_FILTERS, newFilters)

    // add missing URL params if needed
    const paramUpdates = {}

    MANDATORY_URL_PARAM_FILTERS.forEach(item => {
      if (!ctx.$route.query[`${item.key}`]) {
        paramUpdates[`${item.key}`] = state.searchFilters[`${item.key}`]
      }
    })

    if (Object.keys(paramUpdates).length) {
      updateURLparams(ctx, paramUpdates)
    }

    // fetch translations list
    return searchForTranslations(state, commit, { ctx }, isSidepanelDataLoading).then(res => res)
  },
  [GLOBAL_ACTIONS.INIT_MESSAGE_DETAILS_VIEW] ({ commit }, payload) {
    return getMessageDetails(commit, payload)
  },
  [GLOBAL_ACTIONS.LOAD_MESSAGE_DETAILS_PREVIEW] ({ commit }, payload) {
    return getMessageDetails(commit, payload)
  },
  [GLOBAL_ACTIONS.INIT_TASKS_VIEW] ({ commit }, payload) {
    return getMessageDetails(commit, payload)
  },
  [GLOBAL_ACTIONS.INIT_TRANSLATION_RECORD_VIEW] ({ commit }, payload) {
    return getMessageDetails(commit, payload)
  },
  [GLOBAL_ACTIONS.DO_SEARCH] ({ state, commit }, payload) {
    return searchForTranslations(state, commit, {
      ...payload
    }).then(res => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      return res
    })
  },
  [GLOBAL_ACTIONS.DOWNLOAD_SEARCH_RESULTS] ({ state, commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.downloadSearchResults({
      user: state.user,
      filters: payload.filters,
      url: window.location.href
    }).then(res => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      return res
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      return Promise.reject(new Error(`Couldn't start download (${err})`))
    })
  },
  [GLOBAL_ACTIONS.FETCH_BRAND_INFO] ({ commit }, payload) {
    return API.fetchBrandInformation(payload).then(res => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      return res
    })
  },
  [GLOBAL_ACTIONS.FETCH_CANONICAL_NAME_INFO] ({ commit }, payload) {
    let results = []
    async function getCanonicalName (search, page) {
      let res = await API.fetchFromCustomerAPI({
        url: `v4/customers?name=${encodeURIComponent(search)}&page=${page}`,
        type: 'get'
      })

      if (!res.results) return results

      if (page === 1 && res.results.length === 0) {
        // if no results, search for customer ID
        res = await API.fetchFromCustomerAPI({
          url: `v4/customers/${search}`,
          type: 'get'
        })

        if (res.id) {
          results = results.concat(res)
        }
      } else {
        results = results.concat(res.results)

        if (res.total_pages > 1 && res.total_pages > res.page_number) {
          return getCanonicalName(search, res.page_number + 1)
        }
      }

      return results
    }

    return getCanonicalName(payload, 1)
  },
  [GLOBAL_ACTIONS.CANCEL_HT] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.cancelHumanTranslation(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.CHANGE_HT_PRIO] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)

    const CHANGE_PRIORITY_PAGE_SIZE = 50

    return API.recursivePostToTarkinAPI({
      url: 'v1/task/update',
      type: 'post',
      data: payload.data,
      batchesField: 'task_ids'
    }, 0, CHANGE_PRIORITY_PAGE_SIZE).then(tarkinRes => {
      if (tarkinRes.error && tarkinRes.error.length) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return Promise.reject(new Error(`tarkin error ::: ${tarkinRes.error}`))
      }

      return API.getTranslationDetails(payload, commit).then(res => {
        commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res
      }, err => {
        return Promise.reject(new Error(`Error fetching translation details after edit priority ::: ${err}`))
      })
    }, err => {
      return Promise.reject(new Error(`Error editing priority ::: ${err}`))
    })
  },
  [GLOBAL_ACTIONS.SEND_RECORD_TO_EVALUATION] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)

    return API.fetchFromCoreAPI({
      url: `internal/community/translation_records/${payload.id}/evaluations/`,
      type: 'post',
      data: payload.data
    }).then((coreRes) => {
      if (coreRes.error && coreRes.error.length) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return Promise.reject(new Error(`core error ::: ${coreRes.error}`))
      }

      return API.getTranslationDetails(payload, commit).then(res => {
        commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res
      }, err => {
        return Promise.reject(new Error(`Error fetching translation details after sending to evaluation ::: ${err}`))
      })
    })
  },
  [GLOBAL_ACTIONS.SEND_TO_MARKUP_ALIGNER] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.sendToMarkupAligner(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.RESTART_PIPELINE] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.restartPipeline(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.RETRY_PIPELINE] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.retryPipeline(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.DELIVER_MT] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.deliverMachineTranslation(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.PAUSE_HT] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.pauseHumanTranslation(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.RESUME_HT] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.resumeHumanTranslation(payload, commit).then((res) => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, res)
      return res
    })
  },
  [GLOBAL_ACTIONS.SELECT_LIST_ITEM] ({ commit }, payload) {
    // update URL with correct params. Sidepanel with it's watcher will load new data according to url change
    payload.ctx.$router.push({
      name: 'Search',
      query: {
        ...payload.ctx.$route.query,
        index: payload.index,
        pThrId: payload.pThrId,
        pMsgId: payload.pMsgId
      }
    })

    commit(GLOBAL_MUTATIONS.UPDATE_LIST_ITEM_INDEX, payload.index)
  },
  [GLOBAL_ACTIONS.CLEAR_SKIPS] ({ commit }, payload) {
    const payloadApi = {
      task_id: payload.taskId
    }
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.tarkinClearSkips(payloadApi, payload.actor).then(res => {
      if (res.data.assigned_task_ids) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res.data.assigned_task_ids
      }
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Clear skips error ::: ${res.data.error}`)
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Clear skips error ::: ${err}`)
    })
  },
  [GLOBAL_ACTIONS.FORCE_DEGRADATION] ({ commit }, payload) {
    const payloadApi = {
      task_id: payload.taskId
    }
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.tarkinForceDegradation(payloadApi, payload.actor).then(res => {
      if (res.data.rule_set) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res.data.rule_set
      }
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Force degradation error ::: ${res.data.error}`)
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Force degradation error ::: ${err}`)
    })
  },
  [GLOBAL_ACTIONS.ASSIGN_TASK] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    const payloadApi = {
      task_id: payload.taskId,
      editor_name: payload.editorName
    }
    return API.tarkinAssignToEditor(payloadApi, payload.actor).then(res => {
      if (res.data.assigned_task_id) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res.data
      }
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Failed to assign task ::: ${res.data.detail.message}`)
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Failed to assign task ::: ${err}`)
    })
  },
  [GLOBAL_ACTIONS.REASSIGN_TASK] ({ commit }, payload) {
    const payloadApi = {
      assigned_task_id: payload.id
    }
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.tarkinReassignTask(payloadApi, payload.actor).then(res => {
      if (!res.data) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return res.data
      }
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Failed to assign task ::: ${res.data.detail.message}`)
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Failed to assign task ::: ${err}`)
    })
  },
  [GLOBAL_ACTIONS.CANCEL_TASK] ({ commit }, payload) {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
    return API.tarkinCancelTask(payload, commit, payload.actor).then(res => {
      if (res.status === 200) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
        return
      }
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Skip task error ::: ${res.data.error}`)
    }, err => {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      throw new Error(`Skip task error ::: ${err}`)
    })
  }
}

export default actions
