<template>
  <div class="c-TaskDetailsHeader">
    <div class="c-TaskDetailsHeader__leftBlock">
      <div class="c-TaskDetailsHeader__title">
        <div>
          <h5>
            <span>{{ title }}</span>
            <StatusChip
              class="c-TaskDetailsHeader__status"
              :status="task.task_status"
            />
          </h5>

          <ul>
            <li v-if="origin" class="text--ellipse">
              from {{ origin }}
            </li>
            <li v-if="usecaseLabel" class="text--ellipse">
              {{ usecaseLabel }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-TaskDetailsHeader__rightBlock">
      <IdCopyBlock :id="task.id" icon="uid" label="Task ID" />
    </div>
  </div>
</template>

<script>
import IdCopyBlock from '@/components/IdCopyBlock.vue'
import StatusChip from '@/components/StatusChip'
import { TASK_TYPE } from '@/data/enum'
import { USE_CASE_LABEL } from '../../constants/translations'
import { getTagValue } from '@/utils/generalUtils'

export default {
  name: 'TaskDetailsHeader',
  components: {
    IdCopyBlock,
    StatusChip
  },
  props: {
    translation: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    requestData () {
      return this.translation.request
    },
    origin () {
      return getTagValue(this.requestData, 'origin')
    },
    usecaseLabel () {
      return USE_CASE_LABEL[getTagValue(this.requestData, 'use_case')]
    },
    contentType () {
      return getTagValue(this.requestData, 'content_type')
    },
    title () {
      const isSeniorReview = this.task.type === TASK_TYPE.REVIEW
      return isSeniorReview ? `${this.contentType} Review` : `${this.contentType} Task`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
$list-style-padding: 18px;

.c-TaskDetailsHeader {
  display: flex;
  align-items: bottom;
  justify-content: space-between;
  position: relative;

  &__title {
    margin-bottom: calc(#{$base-margin} / 2);

    h5 {
      display: flex;
      align-items: center;

      .c-TaskDetailsHeader__status {
        margin-left: calc(#{$base-margin} / 2);
      }

      span {
        font-family: $secondary-font;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: 600;
      }
    }

    ul {
      display: flex;
      margin: 4px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        max-width: 12rem;
        color: $un-n700;

        &:not(:first-child)::before {
          content: "\2022";
          font-size: 24px;
          vertical-align: top;
          margin: 0 calc(#{$base-margin} / 2);
        }

        &:first-child {
          list-style: none;
        }
      }
    }
  }

  &__rightBlock {
    display: flex;
    align-items: center;

    & > div:nth-child(1) {
      display: flex;
    }
  }
}
</style>
