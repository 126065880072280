export const changelogData = [
  {
    label: 'v2.2.1 (11/12/2024 15:00)',
    link: 'v2.2.1',
    notes: [
      'Deprecate Customer API v1 & v2 usage'
    ]
  },
  {
    label: 'v2.1.6 (09/12/2024 11:00)',
    link: 'v2.1.6',
    notes: [
      'Added translation details refresh button'
    ]
  },
  {
    label: 'v2.1.5 (25/11/2024 16:00)',
    link: 'v2.1.5',
    notes: [
      'Fixed translation not found error not looking in flexible'
    ]
  },
  {
    label: 'v2.1.2 (20/11/2024 16:00)',
    link: 'v2.1.2',
    notes: [
      'Fixed issues related with glossaries in Flexible Translations',
      'Added Language Pair and template in Flexible Translations list',
      'Rolledback background view changes to match x-ray',
      'Fixed incorrect translation state in Flexible Translation details'
    ]
  },
  {
    label: 'v2.1.1 (18/11/2024 10:00)',
    link: 'v2.1.1',
    notes: [
      'Added task and records view to Flexible Translations',
      'Changed flow look in flexible translations',
      'Fixed fallback engine for Fast MT translations'
    ]
  },
  {
    label: 'v2.0.2 (14/11/2024 10:00)',
    link: 'v2.0.2',
    notes: [
      'Fixed TM segment background color'
    ]
  },
  {
    label: 'v2.0.1 (12/11/2024 10:00)',
    link: 'v2.0.1',
    notes: [
      'Introduced new "Flexible Pipelines" view'
    ]
  },
  {
    label: 'v1.8.13 (14/08/2024 11:00)',
    link: 'v1.8.13',
    notes: [
      'Fixed flow in old translation details view'
    ]
  },
  {
    label: 'v1.8.12 (09/08/2024 11:00)',
    link: 'v1.8.12',
    notes: [
      'Change sort order of human translations'
    ]
  },
  {
    label: 'v1.8.10 (07/08/2024 12:00)',
    link: 'v1.8.10',
    notes: [
      'Fix browser search looking for the content inside modal'
    ]
  },
  {
    label: 'v1.8.9 (01/08/2024 17:00)',
    link: 'v1.8.9',
    notes: [
      'Mark HT module as skipped when there are no tasks',
      'Fixed blocking indicator'
    ]
  },
  {
    label: 'v1.8.7 (30/07/2024 16:00)',
    link: 'v1.8.7',
    notes: [
      'Added a copy on click for customer ID in message details'
    ]
  },
  {
    label: 'v1.8.6 (23/07/2024 16:00)',
    link: 'v1.8.6',
    notes: [
      'Refactor x-ray to use flowrunner v2 phases',
      'Fixed bug where modal would not close after clicking on an action',
      'Replace glossary tool link with Portal link in nugget annotations'
    ]
  },
  {
    label: 'v1.8.5 (18/07/2024 10:00)',
    link: 'v1_8_5',
    notes: [
      'Added new filter to filter by "Project ID"',
      'Added new filter to filter by "Template type"',
      'Added Deadline date on translation list view',
      'Allows user to filter by customer ID in the client filter',
      'Tweacked the visual aspect of dates in list view'
    ]
  },
  {
    label: 'v1.8.4 (08/07/2024 10:00)',
    link: 'v1_8_4',
    notes: [
      'Add new api fetch to get file information',
      'Add label for translations with file preprocessing',
      'Add file information on message details popover section',
      'Add task type indicator to Human modules',
      'Fixes instances where "Register" would not appear in the translation details',
      'Made client search filter case insensitive'
    ]
  },
  {
    label: 'v1.8.2 (05/07/2024 10:00)',
    link: 'v1_8_2',
    notes: [
      'Minor visual fixes in translation details view',
      'Added deadline indicator in translation details view',
      'Download output xliff from available steps',
      'Highlight different type of TMs in nugget view',
      'Added markup tag view toggle'
    ]
  },
  {
    label: 'v1.8.1 (21/06/2024 16:00)',
    link: 'v1_8_1',
    notes: [
      'Added confirmation modal to flow change actions',
      'Fixed creation and completion dates in table',
      'Fixed flow labels to be more readable'
    ]
  },
  {
    label: 'v1.8.0 (19/06/2024 16:00)',
    link: 'v1_8_0',
    notes: [
      'Added visual cue when using a fallback engine in segments',
      'Added the project name and link to the translation details',
      'Added the glossary link to the translation details',
      'Added the template type in the translation details',
      'Fixed link to pipelines app in Portal',
      'Removed not working filter "Skip Reason"'
    ]
  },
  {
    label: 'v1.7.49 (26/01/2024 17:50)',
    link: 'v1_7_49',
    notes: [
      'Make requests in batches of 50 tasks to Tarkin via Controlplane to update tasks priority'
    ]
  },
  {
    label: 'v1.7.48 (20/10/2023 12:00)',
    link: 'v1_7_48',
    notes: [
      'Action menu for human translation tasks to clear skips, force task degradation or assign a task to a specific community member',
      'Action menu for translation records to reassign the task'
    ]
  },
  {
    label: 'v1.7.45 (29/09/2023 15:00)',
    link: 'v1_7_45',
    notes: [
      'Fix nuggets view to display the nuggets from all HT steps'
    ]
  },
  {
    label: 'v1.7.44 (21/09/2023 16:00)',
    link: 'v1_7_44',
    notes: [
      'Update diagram to show cancel state'
    ]
  },
  {
    label: 'v1.7.43 (04/09/2023 16:50)',
    link: 'v1_7_43',
    notes: [
      'Fix skips counter of HT tasks'
    ]
  },
  {
    label: 'v1.7.42 (23/08/2023 10:30)',
    link: 'v1_7_42',
    notes: [
      'Add kustomer integration origins'
    ]
  },
  {
    label: 'v1.7.41 (18/08/2023 11:42)',
    link: 'v1_7_41',
    notes: [
      'Add language urdu (ur)'
    ]
  },
  {
    label: 'v1.7.40',
    link: 'v1_7_40',
    notes: []
  },
  {
    label: 'v1.7.39',
    link: 'v1_7_39',
    notes: []
  },
  {
    label: 'v1.7.38',
    link: 'v1_7_38',
    notes: []
  },
  {
    label: 'v1.7.37',
    link: 'v1_7_37',
    notes: []
  },
  {
    label: 'v1.7.36',
    link: 'v1_7_36',
    notes: []
  },
  {
    label: 'v1.7.35',
    link: 'v1_7_35',
    notes: []
  },
  {
    label: 'v1.7.34',
    link: 'v1_7_34',
    notes: []
  },
  {
    label: 'v1.7.33 (27/03/2023 13:00)',
    link: 'v1_7_33',
    notes: [
      'Update VUE_APP_TARKIN_BASE_URL env variable'
    ]
  },
  {
    label: 'v1.7.32 (17/03/2023 13:00)',
    link: 'v1_7_33',
    notes: [
      'Update Flow to Pipeline in Job View'
    ]
  },
  {
    label: 'v1.7.31 (22/02/2023 13:00)',
    link: 'v1_7_31',
    notes: [
      'Change the initial XRay load to have a time window of 1 day instead of 1 week'
    ]
  },
  {
    label: 'v1.7.30 (03/02/2023 16:00)',
    link: 'v1_7_30',
    notes: [
      'Update Translation Profile View'
    ]
  },
  {
    label: 'v1.7.28 (16/09/2022 13:00)',
    link: 'v1_7_28',
    notes: [
      'Add new bs, ca, et, hr, is, lt, lv, mk, sk, sl, sq, sr and uk languages'
    ]
  },
  {
    label: 'v1.7.27 (16/09/2022 09:50)',
    link: 'v1_7_27',
    notes: [
      'Add new flags SVG assets for bs, ca, et, hr, is, lt, lv, mk, sk, sl, sq, sr and uk languages'
    ]
  },
  {
    label: 'v1.7.26 (06/09/2022 15:30)',
    link: 'v1_7_26',
    notes: [
      'Use customer_ids query parameter instead of username for client filter',
      'Use tokenParsed attribute from Keycloak to properly decode tokens'
    ]
  },
  {
    label: 'v1.7.25 (23/06/2022 17:30)',
    link: 'v1_7_25',
    notes: [
      'Add "Download Results" button',
      'Update Flowrunner staging address to new environment'
    ]
  },
  {
    label: 'v1.7.24 (14/04/2022 15:00)',
    link: 'v1_7_24',
    notes: [
      'Update Glossary Tool URL'
    ]
  },
  {
    label: 'v1.7.23 (16/03/2022 16:00)',
    link: 'v1_7_23',
    notes: [
      'Remove button to send translation to Core'
    ]
  },
  {
    label: 'v1.7.22 (07/12/2021 08:00)',
    link: 'v1_7_22',
    notes: [
      'Add config and deployment procedures for the new enviroments for Xray (STG and PROD)',
      'Improve parsing of Trace Details data',
      'Update Flow Details diagram in order to better fit the flexible flows information',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.21 (23/11/2021 15:00)',
    link: 'v1_7_21',
    notes: [
      'update data loading procedure to prevent loading all tasks and translation records outside of message details view',
      'implement partial loaders for loading HT tasks list and nuggets data on message details in order to improve user experience',
      'Fix issue preventing user from searching/filtering list with multiple Clients',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.19 (12/11/2021 13:00)',
    link: 'v1_7_19',
    notes: [
      'Optimizations to the data loading procedure for FAQs with an unusual high amount of HT Tasks',
      'Add pagination of 30 items per page to Tasks and Translation Records lists',
      'Small UI optimizations and bug fixes',
      'IMPORTANT NOTE => One of the consequences of the optimization procedure for FAQs with an unusual high amount of HT Tasks is that we won’t be able to show the nuggets for both legs of HT (it will only show the result of the Senior Review in the Nuggets view) if we have more than 50 HT Tasks, until some changes are done to our systems, which cannot be tackled right now. Thank you for your understanding.'
    ]
  },
  {
    label: 'v1.7.17 (09/11/2021 08:45)',
    link: 'v1_7_17',
    notes: [
      'Add text highlighting (new annotation types) for Tasks and Translation Record views',
      'Overall improvements in terms of code testing',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.14 (26/10/2021 09:00)',
    link: 'v1_7_14',
    notes: [
      'Revamp nuggets view with support for new text higlights (new annotation types) and improvement of nuggets code view',
      'Replacing deprecated lib (momentJS)',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.13 (11/10/2021 15:45)',
    link: 'v1_7_13',
    notes: [
      'Add link to each released version on the Release Notes',
      'Update data loading procedure for Sidepanel in order to clear duplicate data load and inconsistent data display',
      'Fix broken display of Keyboard shortcuts modal',
      'Fix long agent names on TitleSection (MessageDetails) by truncating string and adding a Tooltip',
      'QE score is only shown on last leg of MT, for multistep MT translations (ex: pivoted)',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.10 (27/09/2021 11:00)',
    link: 'v1_7_10',
    notes: [
      'Add multistep HT nuggets to nuggets view on Message Details',
      'Update cancel HT translation endpoint',
      'Fix issue with TM curation usecase bugging the Message Details view',
      'Fix issue logging on Xray in staging that caused the user to refresh the page to gain access after a successful login',
      'Fix issue where non-critical load of information on Message Details was blocking the whole interface, even if only part of the data was missing',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.9 (16/04/2021 09:30)',
    link: 'v1_7_9',
    notes: [
      'Enable use case filter',
      'Remove HT timeout as a Status filter option due to translations not persisting in this state',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.6 (30/03/2021 14:45)',
    link: 'v1_7_6',
    notes: [
      'Add indication on List view for Markup Aligner and Upgrade to HT',
      'Add filter by usecase',
      'Adjust parsing of phases data so that the Flow Diagram on the Message Details represents the translation state better',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.4 (22/03/2021 09:45)',
    link: 'v1_7_4',
    notes: [
      'Recursive loading of Tasks and Translation Records allows to load more than 100 items of each if needed',
      'Add adjusted copy for target text of Tasks or Translation Records depending on the status of the TRs',
      'Various updates to the Mixpanel data',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.7.0 (15/03/2021 14:25)',
    link: 'v1_7_0',
    notes: [
      'Revamp of the Header section with useful links such as “keyboard shortcuts”, “troubleshooting guide” or “product feedback”',
      'Add translation direction filter',
      'Add translation direction info to list view items',
      'Change UI/UX for the content-type filter',
      'Client username now visible on the list view items',
      'Changed Xray url’s for the search view, to make them much smaller and easier to manage (old url’s are supported and converted to the new ones)',
      'Major redesign of message details/side panel',
      'Admin actions can be performed from the side panel view',
      'Translations can be cycled through using arrow left and arrow right while on the side panel view',
      'New “Thread ID” link on the breadcrumbs will take you to the search list with filtered by “thread ID” giving you a overview of messages within the same thread',
      'Added overall QE score information on the message details',
      'Edit priority available now per HT task as well as bulk',
      'New dedicated screen for translation record details with relevant information',
      'Task view and Translation record view now includes a preview of what the editor received and what was the output of the editor (HT)',
      'Includes multiple bug fixes and other small improvements throughout the app'
    ],
    media: [
      {
        type: 'loomVideo',
        code: '328a7050c8334282b7c2037fd2cee411'
      }
    ]
  },
  {
    label: 'v1.6.1 (04/02/2021 14:25)',
    link: 'v1_6_1',
    notes: [
      'Error messages redesign to align with Samora/design system providing more context regarding server-side errors',
      'Fix data visualisation inconsistency around client/brand data betweeen the search and the message details view',
      'Update to Client filter that allows to cover the proper results it should yield',
      'Various updates to the Mixpanel data',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.6.0 (18/01/2021 16:00)',
    link: 'v1_6_0',
    notes: [
      'Editor username on translation record is now a link to the editor profile details',
      'Add tooltip as hover to all relevant dates on Xray',
      'Update Flow Details diagram to better represent the current state of a translation',
      'Add filter by Task ID',
      'Add filter by Translation Record ID',
      'Add send to evaluation action to translation record (only submitted translation records can be sent to evaluation)',
      'Various updates to the Mixpanel data',
      'Small UI optimizations and bug fixes'
    ]
  },
  {
    label: 'v1.5.2 (06/01/2021 15:00)',
    link: 'v1_5_2',
    notes: [
      'Add relevant queue information to the editor Task Details view',
      'Edit priority action now leverages the use of the Tarkin proxy available',
      'Replacing the QE Skipped filter by a more broad Skip Reason filter on Seearch/List view',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.5.0 (31/12/2020 16:50)',
    link: 'v1_5_0',
    notes: [
      'Add support for Senior Reviews on Xray',
      'Fix issue with Flow Details Trace information not being properly updated when previewing the message details',
      'Xray information about Tasks and Translation Records are now being fetched from Tarkin',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.4.6 (21/12/2020 15:00)',
    link: 'v1_4_6',
    notes: [
      'Add copy to clipboard function to Flow Details Trace modal  view',
      'Add skip reason to both List and Details view',
      '“View in Core” links more accurate by taking into account on wether the translation has HT or not',
      'Add elapsed time for translation in progress',
      'Fix issue for missing nuggets and word count for Core Fallback and Pivoted translations',
      'Remove Xliff CDATA tags and also removal of source text from translated text on Xliff translations',
      'Add agent and author data to message details (only available for Tickets content-type)',
      'Removing logic where task status was wrongfully inferred by the translation record status',
      'Task status are now defined as enqueued or dequeued',
      'Release E2E tests for Xray'
    ]
  },
  {
    label: 'v1.4.2 (02/12/2020 19:08)',
    link: 'v1_4_2',
    notes: [
      'Add user group verification when logging into Xray'
    ]
  },
  {
    label: 'v1.4.1 (02/12/2020 09:15)',
    link: 'v1_4_1',
    notes: [
      'Update layout of table items in Search list view, with a more concise and up-to-date view of information',
      'Update flow details trace view to make it more user friendly',
      'Fix issue where xliff Ticket original and translated texts were not appearing properly',
      'Setup Mixpanel events',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.4.0 (16/11/2020 13:00)',
    link: 'v1_4_0',
    notes: [
      'Content-type filter is now a Select. From now on, Xray does not need to preset a content-type',
      'Added QE skipped filter to Search. If it’s selected you will only find translations that have been QE skipped',
      'Added Client filter, which will enable to filter a Search by the client’s Canonical name',
      'Added search by External Message ID (will only work for Tickets content-type)',
      'Added search by External Thread ID (will only work for Tickets content-type)',
      'Strip HTML tags from original text and translations on both the List view and the Message Details for easier reading (especially on Tickets)',
      'Clicking on a List table row will open the “preview” sidepanel for a quick peek of the details of a translation',
      'Clicking on ESC (keyboard) closes the “preview” sidepanel',
      'Cmd + click on the link icon on a List table row will now open the Message details on a new tab',
      'Truncate all ID’s on Xray to max 6 characters, all with “click to copy to clipboard” shortcut',
      'Fix bug where some translations were not showing nuggets',
      'Fix bug with glossary HTML format appearing inside a TM on nuggets view with improper format'
    ],
    media: [
      {
        type: 'loomVideo',
        code: '650d9b0a8e854a5d8e7d98eaa564242f'
      }
    ]
  },
  {
    label: 'v1.3.5 (27/10/2020 17:15)',
    link: 'v1_3_5',
    notes: [
      'Fix issue with link to Glossary Tools (from Nuggets) where we should use "glossary id" and not "translation profile id"',
      'Re-enable retry deliver MT action on the Message Details Admin Action panel'
    ]
  },
  {
    label: 'v1.3.4 (26/10/2020 12:00)',
    link: 'v1_3_4',
    notes: [
      'Fix issue where cancel editing button on tasks (in Message Details) was not appearing properly',
      'Re-enable retry pipeline/translation action on the Message Details Admin Action panel'
    ]
  },
  {
    label: 'v1.3.3 (20/10/2020 17:25)',
    link: 'v1_3_3',
    notes: [
      'Fix issue with app loader and screen lock disappearing before all data was loaded'
    ]
  },
  {
    label: 'v1.3.2 (20/10/2020 16:15)',
    link: 'v1_3_2',
    notes: [
      'Fix issue with broken links to Customer Portal page for the Translation Profiles',
      'Translation Memory highlights on Nuggets view now links to TM server',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.3.1 (06/10/2020 15:30)',
    link: 'v1_3_1',
    notes: [
      'Fix issue with Brand filter not working on Safari',
      'Rename Brand Username to Client User name',
      'Optimization of the QE scores on the Nuggets view',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.3.0 (29/09/2020 16:42)',
    link: 'v1_3_0',
    notes: [
      'Created a dedicated view for the Changelog/Release notes of Xray',
      'Created the quick-preview of the Message Details, so that the user can check the details without leaving the Search screen',
      'Improved Nuggets layout on Message Details by highlighting Glossary terms (with the correct link to the Glossary tool)',
      'Improved Nuggets layout on Message Details by highlighting Translation Memories',
      'Improved Nuggets layout with the display of the QE score per nugget',
      'Temporarily removed the unavailable actions from the Actions Panel on the Message Details',
      'Added labels to all dates and times with the timezone (UTC), on hover',
      'Support of old URL parameter "chat_id", which was replaced previously by "thread_id" so that older Search links dont appear broken',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.7 (17/09/2020 16:38)',
    link: 'v1_2_7',
    notes: [
      'Fixed issue where original text and translation on Search Table was not rendering HTML symbols properly',
      'Fixed issue where option of sending translation to Core was available even if it was already sent to Core',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.6 (29/08/2020 12:34)',
    link: 'v1_2_6',
    notes: [
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.5 (28/08/2020 16:00)',
    link: 'v1_2_5',
    notes: [
      'Added "Actions Panel" to the Message Details view in order to act upon a multitude of scenarios with the translations',
      'Searching for a "Brand" on the filters only needs two characters instead of three to trigger the search',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.4 (27/08/2020 10:49)',
    link: 'v1_2_4',
    notes: [
      'Changes to Xray´s logic in order to comply with some Backend changes',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.3 (26/08/2020 09:58)',
    link: 'v1_2_3',
    notes: [
      'Added “Content Type" as a filter on the Search view',
      'Added “Brand" as a filter on the Search view',
      'Added “Brand name" to the information displayed on the Search Table',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.2 (18/08/2020 11:46)',
    link: 'v1_2_2',
    notes: [
      'Added “Core Fallback” as an option for the Architecture filter',
      'Chat ID filter is now non-interface blocking (meaning that more filters can be combined with this one)',
      'Link to Core MT-jobs from Xray for Core tasks (both from Search and from Message Details)',
      'Fix various minor issues related with UI',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.1 (05/08/2020 11:37)',
    link: 'v1_2_1',
    notes: [
      'Search results can now be navigated through using a numbered pagination',
      'Added an active state to the filters on the Search view (to better check which filters are active)',
      'Added copy-paste feature to the ID’s on the top part of the Message Details & Task Details (just click on them to copy)',
      'Truncate long texts on Search table (Original text and Translation) to display max 3 lines',
      'Xray is now using Samora (not a visible change)',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.2.0 (21/07/2020 08:39)',
    link: 'v1_2_0',
    notes: [
      'Update Search filters UI with news inputs (GroupedInputText and ChipMultiSelect)',
      'Create view for the Task Details',
      'Create Breadcrumbs for easier context and navigation while diving deeper into the information',
      'Fine-tuning styling and copy overall',
      'Small UI optimisations and bug fixes',
      'With this release came the need to change the URL’s structure to reflect the hierarchy of information on Xray and to prepare for the new content-types to come (Tickets). The former URL with /translation/:translationId has been replaced for the following structure: /conversation/:conversationId/message/:messageId. Be aware that this is a breaking change, so the old /translation URL’s won’t work anymore.'
    ]
  },
  {
    label: 'v1.1.0 (07/07/2020 12:41)',
    link: 'v1_1_0',
    notes: [
      'Add Edit Priority feature to Task List on the Details view',
      'Source Language, Target Language, Document Type and Origin filters now accept multiple options',
      'Add TM’s and Untranslatables to the Nuggets details',
      'Add button-based lateral scroll to the Search table and the Nuggets details',
      'Add Flow Details (as a diagram), which should appear for Maestro translations (this is a db dependent feature, so older Maestro translations will fallback as Core translations to the previous table-based Flow Details).',
      'Fine-tuning styling and copy overall',
      'Small UI optimisations and bug fixes',
      'We also have a new (simpler) URL for Xray, which should be the one to be used from now on: https://xray.unbabel.com/ . The old one will work also for the time being: https://x-ray.unbabel.com/. Hopefully we can decommission it in a near future.'
    ]
  },
  {
    label: 'v1.0.5 (26/06/2020 11:50)',
    link: 'v1_0_5',
    notes: [
      'Add Sentry (logger) to the project to help with bug-fixing context',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.0.4 (25/06/2020 22:06)',
    link: 'v1_0_4',
    notes: [
      'Fix bug preventing searching "From date" and "To date" from working properly',
      'Add missing filters when comparing this interface to chat API dashboard',
      'Add "home" (search) link to the Unbabel logo on the header',
      'Add "failed" status to Status filter',
      'Pressing enter now triggers Search (from text input fields)',
      'Disable Nuggets button for Arch1 and display message next to it “unavailable for Arch1”',
      'Improve Cancel HT action with UI update after success',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.0.3 (19/06/2020 16:11)',
    link: 'v1_0_3',
    notes: [
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.0.2 (19/06/2020 15:00)',
    link: 'v1_0_2',
    notes: [
      'Add "Arch" as column on Search view',
      'Improve security and authentication procedures for Xray',
      'Added Cancel HT action to the interface',
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.0.1 (18/06/2020 19:49)',
    link: 'v1_0_1',
    notes: [
      'Small UI optimisations and bug fixes'
    ]
  },
  {
    label: 'v1.0.0 (18/06/2020 18:06)',
    link: 'v1_0_0',
    notes: [
      'First release of Xray to Production'
    ]
  }
]
