import { EventType, ModuleState, TranslationState, StatusToTranslationState, EventToModuleState } from '../constants/enums'
import { formatDistanceStrict, differenceInSeconds } from 'date-fns'
import { decode } from 'js-base64'

export function decodePayload (payload) {
  if (!payload) return
  return JSON.parse(decode(payload))
}

export function parseExecution (payload) {
  let startedAt, endedAt
  const attributes = payload.search_attributes?.indexed_fields ?? {}

  if (payload.start_time) {
    startedAt = new Date(payload.start_time.seconds * 1000)
    startedAt = startedAt.toUTCString()
  }

  if (payload.close_time) {
    endedAt = new Date(payload.close_time.seconds * 1000)
    endedAt = endedAt.toUTCString()
  }

  let duration = payload.execution_duration?.seconds

  if (!duration && endedAt) {
    duration = differenceInSeconds(new Date(endedAt), new Date(startedAt))
  }

  return {
    executionId: payload.execution.workflow_id,

    duration,

    startedAt,
    endedAt,

    type: payload.type.name,
    status: StatusToTranslationState[payload.status],
    threadId: decodePayload(attributes?.ThreadID?.data),
    externalThreadId: decodePayload(attributes?.ExternalThreadId?.data),
    messageId: decodePayload(attributes?.MessageID?.data),
    externalMessageId: decodePayload(attributes?.ExternalMessageID?.data),
    projectId: decodePayload(attributes?.ProjectID?.data),
    customerId: decodePayload(attributes?.CustomerID?.data),
    pipelineId: decodePayload(attributes?.PipelineID?.data),
    sourceLanguage: decodePayload(attributes?.SourceLanguage?.data),
    targetLanguage: decodePayload(attributes?.TargetLanguage?.data),
    templateType: decodePayload(attributes?.TemplateType?.data)
  }
}

function getAttribute (task, name) {
  if (!task) return { root: {}, payload: {}, result: {} }

  if (!name) {
    name = Object.keys(task.Attributes ?? {})?.[0]
  }

  const root = task.Attributes?.[name]

  return {
    root,
    payload: decodePayload(root?.input?.payloads?.[0]?.data),
    results: root?.result?.payloads?.map(payload => decodePayload(payload?.data))
  }
}

function parseModuleState (type) {
  return EventToModuleState[type] ?? ModuleState.IN_PROGRESS
}

export function parseExecutionDetails (tasks) {
  const rootEvent = tasks.find(e => e.event_type === EventType.EVENT_TYPE_WORKFLOW_EXECUTION_STARTED)
  const { payload } = getAttribute(rootEvent)

  const resultEvent = tasks.find(e => e.event_type === EventType.EVENT_TYPE_WORKFLOW_EXECUTION_COMPLETED)
  const { results } = getAttribute(resultEvent)

  if (!payload) throw new Error('No start task found')

  const stepExecution = []
  let status = TranslationState.PENDING

  tasks.forEach(task => {
    const data = getAttribute(task)
    let step

    switch (task.event_type) {
      // Task events
      case EventType.EVENT_TYPE_ACTIVITY_TASK_SCHEDULED:
        stepExecution.push({
          module: data.root.activity_type?.name,
          status: ModuleState.SCHEDULED,
          input: data.payload,
          startedAt: new Date(task.event_time.seconds * 1000),
          eventId: task.event_id
        })
        break
      case EventType.EVENT_TYPE_ACTIVITY_TASK_COMPLETED:
        step = stepExecution.find(e => e.eventId === data.root.scheduled_event_id)
        if (!step) break
        step.status = ModuleState.COMPLETED
        step.results = data.results?.flat()
        step.completedAt = new Date(task.event_time.seconds * 1000)
        step.eventId = data.root.scheduled_event_id
        break
      case EventType.EVENT_TYPE_ACTIVITY_TASK_STARTED:
      case EventType.EVENT_TYPE_ACTIVITY_TASK_CANCELED:
      case EventType.EVENT_TYPE_ACTIVITY_TASK_FAILED:
      case EventType.EVENT_TYPE_ACTIVITY_TASK_CANCEL_REQUESTED:
      case EventType.EVENT_TYPE_ACTIVITY_TASK_TIMED_OUT:
        step = stepExecution.find(e => e.eventId === data.root.scheduled_event_id)
        if (!step) break
        step.status = parseModuleState(task.event_type)
        step.eventId = data.root.scheduled_event_id
        break

      // Workflow events
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_STARTED:
        status = TranslationState.IN_PROGRESS
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_COMPLETED:
        status = TranslationState.COMPLETED
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_FAILED:
        status = TranslationState.FAILED
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_TIMED_OUT:
        status = TranslationState.TIMED_OUT
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_CANCEL_REQUESTED:
        status = TranslationState.CANCELED
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_CANCELED:
        status = TranslationState.CANCELED
        break
      case EventType.EVENT_TYPE_WORKFLOW_EXECUTION_TERMINATED:
        status = TranslationState.TERMINATED
        break
    }
  })

  const duration = tasks[tasks.length - 1].event_time.seconds - tasks[0].event_time.seconds

  return {
    status,
    duration: formatDistanceStrict(0, duration * 1000),
    ...payload,
    stepExecution,
    results: results?.flatMap(result => result.output)
  }
}

export function parseTranslationStatusToStyle (value) {
  const statusStyles = {
    [TranslationState.COMPLETED]: 'is-success',
    [TranslationState.TERMINATED]: 'is-attention',
    [TranslationState.CANCELED]: 'is-attention',
    [TranslationState.FAILED]: 'is-error',
    [TranslationState.TIMED_OUT]: 'is-attention'
  }

  return {
    labelStyle: statusStyles[value] ?? 'is-info',
    labelType: 'is-subtle'
  }
}
