import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

import watchtower from '@/watchtower/store'
import customersStore from '@/watchtower/store/customers'
import projectsStore from '@/watchtower/store/projects'
import tasksStore from '@/watchtower/store/tasks'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => (state),
  getters,
  actions,
  mutations,
  modules: {
    watchtower,
    customers: customersStore,
    projects: projectsStore,
    tasks: tasksStore
  }
})
