<template>
  <Card title="Translation Text">
    <table slot="body" class="c-TaskTranslation__table">
      <tbody>
        <!-- Header -->
        <tr class="c-TaskTranslation__tableHeader">
          <td>
            <div class="c-TaskTranslation__languageHeader">
              <div class="c-TaskTranslation__language">
                <img :src="`/flags-light/${data.source.language}.svg`" />
                <p>
                  <span>{{ data.source.languageLabel }}</span>
                  <span> by {{ data.source.author }} </span>
                </p>
              </div>

              <span class="c-TaskTranslation__numWords">
                {{ data.source.words }} words
              </span>
            </div>
          </td>

          <td>
            <div class="c-TaskTranslation__languageHeader">
              <div class="c-TaskTranslation__language">
                <img :src="`/flags-light/${data.target.language}.svg`" />
                <p>
                  <span>{{ data.target.languageLabel }}</span>
                  <span> by {{ data.target.author }} </span>
                </p>
              </div>
              <span class="c-TaskTranslation__numWords">
                {{ data.target.words }} words
              </span>
            </div>
          </td>
        </tr>

        <!-- Segments -->
        <tr v-for="index in numberOfSegments" :key="index">
          <td
            v-for="(segments, idx) in [
              data.sourceSegments,
              data.targetSegments
            ]"
            :key="idx"
          >
            <p
              v-if="!segments[index - 1] && index === 1"
              class="c-TaskTranslation__noTranslationRecord"
            >
              No Translation Record submitted yet...
            </p>
            <Segment
              v-if="segments[index - 1]"
              :segment="segments[index - 1]"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div slot="footer">
      <NuggetsLegend />
    </div>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import { Card } from '@unbabel/ui'
import NuggetsLegend from '@/components/NuggetsLegend'
import Segment from '../TranslationSegments/Segment'

import { clone, getTagValue } from '@/utils/generalUtils'
import { TASK_TYPE_EDITOR_LABEL } from '@/data/enum'
import { languageOptions } from '@/data/languages'
import { parseSegments } from '../../utils/parsingUtils'

export default {
  name: 'TaskTranslation',
  components: {
    Card,
    Segment,
    NuggetsLegend
  },
  props: {
    translation: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return { customer: null }
  },
  computed: {
    ...mapGetters({ getCustomer: 'customers/getCustomer' }),
    focusedSteps () {
      let source
      const inversedStepExecution = clone(
        this.translation.stepExecution.filter(step =>
          step.module.includes('translation')
        )
      ).reverse()

      const currentIndex = inversedStepExecution.findIndex(step => {
        if (!step.module.includes('human_translation')) return false

        // eslint-disable-next-line camelcase
        const data =
          step.results?.[0]?.transient_data ?? step.input.transient_data ?? {}
        return data?.task_results.some(t => t.id === this.task.id)
      })

      const target = inversedStepExecution[currentIndex]
      if (target?.module === 'human_translation-resume') {
        source = inversedStepExecution[currentIndex + 2]
      } else {
        source = inversedStepExecution[currentIndex + 1]
      }

      return { target, source }
    },
    options () {
      const glossaryId = getTagValue(this.translation.workflow, 'glossary_id')
      const customerId = this.customer?.canonical_name
      return { customerId, glossaryId }
    },
    data () {
      const targetResult = this.focusedSteps.target.results?.[0]
      const sourceResult = this.focusedSteps.source.results?.[0]

      const sourceSegments = sourceResult?.output?.document?.target_segments

      let targetSegments = targetResult?.output?.document?.target_segments
      if (!this.task.in_progress) targetSegments = []

      return {
        sourceSegments: parseSegments(sourceSegments ?? [], this.options),
        targetSegments: parseSegments(targetSegments ?? [], this.options),
        source: this.generateDataObj(this.focusedSteps.source),
        target: this.generateDataObj(this.focusedSteps.target)
      }
    },
    numberOfSegments () {
      return this.data.sourceSegments?.length ?? 0
    }
  },
  methods: {
    generateDataObj (step) {
      const { config } = step.input

      let author = TASK_TYPE_EDITOR_LABEL[config.task_type] ?? 'Author'
      if (step.module.includes('machine_translation')) {
        author = 'Machine Translation'
      }

      return {
        language: config.target_language,
        languageLabel:
          languageOptions[config.target_language]?.label ??
          config.target_language,
        author,
        words: this.task.num_words
      }
    }
  },
  watch: {
    translation: {
      async handler (newVal) {
        this.customer = await this.getCustomer(newVal.request.customer_id)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TaskTranslation {
  &__table {
    width: 100%;
  }

  &__languageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }

  &__language {
    display: flex;

    img {
      height: 40px;
      object-fit: contain;
      margin-right: $base-margin;
    }

    p span {
      &:first-child {
        font-family: $secondary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $un-n900;
      }
      &:last-child {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $un-n700;
        display: block;
      }
    }
  }

  &__numWords {
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n700;
  }

  &__text {
    font-family: $primary-font;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: $un-n900;
    overflow-wrap: break-word;
  }

  &__noTranslationRecord {
    @extend .d-flex, .center-xs;
    font-family: $primary-font;
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $un-n700;
  }
}

tr > td {
  width: 50%;
  &:first-child {
    padding-right: calc(#{$base-padding} * 1.5);
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -16px;
      right: 0;
      width: 1px;
      height: calc(100% + 16px + 16px);
      background-color: $un-n200;
    }
  }

  &:last-child {
    padding-left: calc(#{$base-padding} * 1.5);
  }
}
</style>
