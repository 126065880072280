import Vue from 'vue'
import { __handleTasks } from '@/utils/parsingUtils'
import { getTasks, getTranslationRecords } from '../services/tarkinService'

const getDefaultState = () => ({
  translationTasks: {},
  loading: {}
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setTask (state, { id, value }) {
      Vue.set(state.translationTasks, id, value)
    },
    setLoading (state, { id, promise }) {
      Vue.set(state.loading, id, promise)
    }
  },
  actions: {
    async getTranslationTasks ({ state, commit }, { translationId, force }) {
      if (!force && state.translationTasks[translationId]) {
        return state.translationTasks[translationId]
      }

      const promise = Promise.all([getTasks(translationId), getTranslationRecords(translationId)])
      commit('setLoading', { id: translationId, promise })

      const [tasks, translationRecords] = await promise

      translationRecords.forEach(translationRecord => {
        const { task, ...record } = translationRecord
        const taskIndex = tasks.findIndex(({ id }) => id === task.id)

        if (taskIndex >= 0) {
          tasks[taskIndex] = { ...tasks[taskIndex], ...task, translation_records: tasks[taskIndex].translation_records || [] }
          tasks[taskIndex].translation_records.push(record)
        }
      })

      const parsedTasks = __handleTasks(tasks)?.parsedTasksArray

      commit('setTask', { id: translationId, value: parsedTasks })
      commit('setLoading', { id: translationId, promise: null })

      return parsedTasks
    }
  }
}
