import Vue from 'vue'

import * as customerService from '../services/customerService'

const getDefaultState = () => ({
  customers: {},
  loading: {}
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setCustomer (state, { id, value }) {
      Vue.set(state.customers, id, value)
    },
    setLoading (state, { id, promise }) {
      Vue.set(state.loading, id, promise)
    }
  },
  actions: {
    async getCustomer ({ state, commit }, { id, force }) {
      if (!id) return

      if (!force && state.customers[id]) {
        return state.customers[id]
      }

      if (state.loading[id]) {
        return state.loading[id]
      }

      const promise = customerService.getCustomer(id)

      commit('setLoading', { id, promise })
      const data = await promise
      commit('setCustomer', { id, value: data })
      commit('setLoading', { id, promise: null })

      return data
    },
    async fetchCustomers ({ commit, dispatch }, query = '') {
      try {
        let data = await customerService.searchCustomers(query)

        data.forEach(customer => commit('setCustomer', { customerId: customer.id, value: customer }))

        if (data.length === 0) {
          const customer = await dispatch('getCustomer', query)
          if (customer) data = [customer]
        }

        return data
      } catch (e) {
        throw new Error(`Error searching for customers :: ${e}`)
      }
    }
  }
}
