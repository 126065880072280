import { render, staticRenderFns } from "./MessageTranslationProfileSection.vue?vue&type=template&id=285203b1&scoped=true&"
import script from "./MessageTranslationProfileSection.vue?vue&type=script&lang=js&"
export * from "./MessageTranslationProfileSection.vue?vue&type=script&lang=js&"
import style0 from "./MessageTranslationProfileSection.vue?vue&type=style&index=0&id=285203b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285203b1",
  null
  
)

export default component.exports