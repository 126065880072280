import axios from 'axios'

import { parseExecution, parseExecutionDetails } from './parsers'

const BASE_URL = `${process.env.VUE_APP_FLEX_API_URL}/bff`

export function encodeURI (payload) {
  if (!payload) return ''
  return Object.entries(payload)
    .filter(([_, body]) => body.value)
    .map(([key, body]) => {
      let out = `${key} ${body.symbol} `

      if (Array.isArray(body.value)) {
        out += `(${body.value.map(item => `"${item}"`).join(',')})`
      } else {
        out += `"${encodeURIComponent(body.value)}"`
      }

      return out
    }).join(' AND ')
}

export async function getAllTranslations (payload) {
  const { nextPageToken, query } = payload

  const requestUrl = `${BASE_URL}/workflows?next_page_token=${nextPageToken ?? ''}&query=${encodeURI(query)}`
  const { data } = await axios.get(requestUrl)

  return {
    results: data.executions?.map(parseExecution),
    nextPageToken: data.next_page_token
  }
}

export async function getTranslation (translationId) {
  const requestUrl = `${BASE_URL}/workflows/${translationId}`
  const { data } = await axios.get(requestUrl)
  return parseExecutionDetails(data)
}
