import axios from 'axios'

const BASE_URL = `${process.env.VUE_APP_CUSTOMER_API_URL}/latest/v4/customers`

export async function getCustomer (id) {
  try {
    const { data } = await axios.get(`${BASE_URL}/${id}`)
    return data
  } catch (e) {
    console.error(e)
  }
}

export async function searchCustomers (query) {
  const requestUrl = `${BASE_URL}?limit=20&name=${encodeURIComponent(query)}`

  const { data } = await axios.get(requestUrl)
  return data.results
}
