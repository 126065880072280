<template>
  <div>
    <TaskDetailsHeader class="mb-2" :translation="translation" :task="task" />

    <TranslationFlowMessageDetails class="mb-2" :translation="translation" />

    <QueueDetails class="mb-2" :task="task" />

    <TaskTranslation class="mb-2" :translation="translation" :task="task" />

    <TaskRecords :task="task" :translation="translation" />
  </div>
</template>

<script>
import TranslationFlowMessageDetails from '../TranslationDetails/TranslationFlowMessageDetails'
import TaskDetailsHeader from './TaskDetailsHeader'
import QueueDetails from './QueueDetails'
import TaskTranslation from './TaskTranslation'
import TaskRecords from './TaskRecords'

export default {
  name: 'TaskDetails',
  components: {
    TaskDetailsHeader,
    TranslationFlowMessageDetails,
    QueueDetails,
    TaskTranslation,
    TaskRecords
  },
  props: {
    translation: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
