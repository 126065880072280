import { languageOptions } from '@/data/languages'
import { FILTERS_DICT, FIXED_TEMPLATES, TEMPLATE_LABEL } from '@/data/enum'
import { TranslationState } from './enums'

export function languageFormOptions () {
  return Object.keys(languageOptions).map(key => {
    return {
      label: languageOptions[`${key}`].label,
      value: key
    }
  })
}

export function templateFormOptions () {
  return Object.values(FIXED_TEMPLATES).map(template => {
    return {
      label: TEMPLATE_LABEL[template],
      value: template
    }
  })
}

export const STATUS_OPTIONS = [
  {
    label: TranslationState.PENDING,
    value: TranslationState.PENDING,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.IN_PROGRESS,
    value: TranslationState.IN_PROGRESS,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.COMPLETED,
    value: TranslationState.COMPLETED,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.FAILED,
    value: TranslationState.FAILED,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.CANCELED,
    value: TranslationState.CANCELED,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.TERMINATED,
    value: TranslationState.TERMINATED,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.CONTINUE_AS_NEW,
    value: TranslationState.CONTINUE_AS_NEW,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  },
  {
    label: TranslationState.TIMED_OUT,
    value: TranslationState.TIMED_OUT,
    labelType: 'is-subtle',
    labelStyle: 'is-inverted',
    type: 'overall'
  }
]

export const ID_SELECT_OPTIONS = [
  { label: 'UID', value: FILTERS_DICT.UID },
  { label: 'Project ID', value: FILTERS_DICT.PROJECT_ID },
  { label: 'Ext. Msg. ID', value: FILTERS_DICT.EXTERNAL_ID },
  { label: 'Thread ID', value: FILTERS_DICT.THREAD_ID },
  { label: 'Ext. Thread ID', value: FILTERS_DICT.THREAD_EXTERNAL_ID }
]
