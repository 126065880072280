<template>
  <Card class="c-TranslationFlowMessageDetails" title="Pipeline Group">
    <div
      slot="header-actions"
      class="c-TranslationFlowMessageDetails__cardActions"
    >
      <a
        v-if="customer"
        :href="redirect('translation_profile')"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          label="Portal"
          button-type="is-outlined"
          icon="external-hyperlink"
        />
      </a>
    </div>

    <div slot="body" class="c-TranslationFlowMessageDetails__cardBody">
      <CardBodyLayout>
        <tr colspan="6">
          <td>
            <p>Client</p>
            <AsyncEntityNameCell
              :id="customerID"
              :fetch="(id) => getCustomer({ id })"
              entityKey="friendly_name"
            />
          </td>
          <td>
            <p>Pipeline Group</p>
            <div>{{ pipelineGroupName }}</div>
          </td>
          <td>
            <p>Register</p>
            <div>{{ register }}</div>
          </td>
          <td>
            <p>Pipeline ID</p>
            <CopyID :extendable="false" small :itemId="translation.workflow.id" entity="pipeline" />
          </td>
          <td>
            <p>Customer ID</p>
            <CopyID :extendable="false" small :itemId="customerID" entity="customer" />
          </td>
          <td v-if="glossary">
            <p>Glossary</p>
            <div>
              <a
                :href="redirect('glossary')"
                target="_blank"
                rel="noopener noreferrer"
                class="glossary-btn pb-1"
              >
                <Icon icon="external-hyperlink" />
                Open glossary
              </a>
            </div>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { mapActions } from 'vuex'
import { Card, Button, Icon } from '@unbabel/ui'
import CopyID from '../CopyID'
import {
  generateCPLinks,
  getTagValue
} from '@/utils/generalUtils'
import CardBodyLayout from '@/components/CardBodyLayout'
import AsyncEntityNameCell from '../AsyncEntityNameCell'

export default {
  name: 'TranslationFlowMessageDetails',
  components: {
    Card,
    Button,
    Icon,
    CopyID,
    AsyncEntityNameCell,
    CardBodyLayout
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return { customer: null }
  },
  computed: {
    customerID () {
      return this.translation.request.customer_id || 'Unkwown'
    },
    register () {
      return this.translation.request.tone
    },
    pipelineGroupName () {
      return (
        getTagValue(this.translation.workflow, 'translation_profile_name') ??
        this.translation.workflow.brand_id ??
        'Unkwown'
      )
    },
    glossary () {
      const requestedGlossary = getTagValue(this.translation.request, 'glossary_id')
      return getTagValue(this.translation.workflow, 'glossary_id', requestedGlossary)
    },
    columns () {
      return !this.glossary ? 5 : 6
    }
  },
  methods: {
    ...mapActions({
      getCustomer: 'customers/getCustomer'
    }),
    redirect (type) {
      return generateCPLinks(
        {
          customer: this.customer,
          translation_profile: {
            id: this.translation.workflow?.brand_id,
            glossary_id: this.glossary
          }
        },
        type
      )
    }
  },
  created () {
    this.getCustomer({ id: this.translation.request.customer_id }).then(customer => {
      this.customer = customer
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationFlowMessageDetails {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  td {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0 !important;
    }
    div {
      @extend .mt-1, .text--body-2, .text--light;
    }
  }

  td {
    vertical-align: middle;

    .glossary-btn {
      display: flex;
      align-items: flex-end;
      font-size: 12px;

      .c-Icon {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px;
      }
    }
  }
}
</style>
