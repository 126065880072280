<template>
  <ul class="c-NuggetsLegend">
    <li><span>Anonymisation</span></li>
    <li><span>Glossary</span></li>
    <li><span>No translate</span></li>
    <li><span>Other</span></li>

    <li class="with-background"><span>TMs</span></li>
    <li class="with-background"><span>ICE matches</span></li>

    <li class="with-background"><span>Blocked TMs</span></li>
    <li class="with-background"><span>Blocked ICE matches</span></li>
    <li class="with-background"><span>Blocked by QE</span></li>
  </ul>
</template>

<script>
export default {
  name: 'NuggetsLegend',
  props: {
    wrap: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-NuggetsLegend {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  li {
    margin: 0 $base-margin 0 calc(#{$base-margin} * 2);
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    span {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 12px;
    }

    &.with-background::before {
      content: '';
      left: calc(-#{$base-margin} * 1.5);
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      top: 2px;
    }

    &:nth-child(1) span {
      @extend .text--bold;
      color: $un-anon;
    }
    &:nth-child(2) span {
      @extend .text--bold;
      color: $un-glossary;
    }
    &:nth-child(3) span {
      @extend .text--bold;
      color: $un-no-trans;
    }
    &:nth-child(4) span {
      @extend .text--bold;
      color: $un-other-annot;
    }

    &:nth-child(5)::before {
      background-color: $un-tm;
    }
    &:nth-child(6)::before {
      content: '';
      left: calc(-#{$base-margin} * 1.5);
      background-color: $un-ice-tm-bg;
    }
    &:nth-child(7)::before {
      border-left: 2px solid $un-locked-tm;
      background-color: $un-tm;
      margin-right: 10px;
    }
    &:nth-child(8)::before {
      border-left: 2px solid $un-ice-tm;
      background-color: $un-ice-tm-bg;
      margin-right: 10px;
    }
    &:nth-child(9)::before {
      border-left: 2px solid $un-qe-tm;
      background-color: $un-qe-tm-bg;
      margin-right: 10px;
    }
  }
}
</style>
