import axios from 'axios'

const BASE_URL = `${process.env.VUE_APP_API_URL}/tarkin/api/v1`

const FILTERS = {
  records: (jobId) => `[{"field":"group_id","op":"==","value":"${jobId}"}]`,
  tasks: (jobId) => `[{"field":"group_id","op":"==","value":"${jobId}"}]`,
  task: (taskId) => `[{"field":"id","op":"==","value":"${taskId}"}]`
}

async function genericGetFromTarkin (url, filter) {
  try {
    const newUrl = `${url}?page=1&page_size=200&filter=${filter}`
    const { data } = await axios.get(newUrl)
    return data.items
  } catch (e) {
    console.error(e)
  }
}

export async function getTranslationRecords (jobId) {
  return genericGetFromTarkin(`${BASE_URL}/translation_records`, FILTERS.records(jobId))
}

export async function getTasks (jobId) {
  return genericGetFromTarkin(`${BASE_URL}/task`, FILTERS.tasks(jobId))
}

export async function getTaskDetails (taskId) {
  return genericGetFromTarkin(`${BASE_URL}/task`, FILTERS.task(taskId))
}
