<template>
  <Card :title="`Human translation tasks (${tasks.length})`">
    <div slot="body">
      <div
        v-if="loadingTasks"
        class="d-flex center-xs text--body-2 text--light py-4"
      >
        Loading tasks
      </div>

      <div
        v-else-if="tasks.length === 0"
        class="d-flex center-xs text--body-2 text--light py-4"
      >
        No tasks have been created yet.
      </div>

      <table v-else>
        <thead>
          <tr>
            <th />
            <th>Information</th>
            <th>Status</th>
            <th>Editors</th>
            <th>Devices</th>
            <th>Deadline</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in tasks" :key="item.id" @click="onRowClicked(item)">
            <td>
              <p class="text--bold">{{ item.parsed_type || item.type }}</p>
              <p class="text--caption text--light">
                Priority {{ item.priority }}
              </p>
              <CopyID
                :extendable="false"
                :itemId="item.id"
                class="mt-1"
                entity="task"
                small
              />
            </td>

            <td>
              <div class="text--caption--demi my-1">
                <span>Skips</span>
                <span class="text--light ml-1">
                  {{ item.task_skipped_count }}
                </span>
              </div>

              <div class="text--caption--demi mb-1">
                <span>Words</span>
                <span class="text--light ml-1">{{ item.num_words || 0 }}</span>
              </div>

              <div class="text--caption--demi">
                <span> Edit distance </span>
                <span class="text--light ml-1">
                  {{ item.task_edit_distance }}
                </span>
              </div>
            </td>

            <td>
              <StatusChip
                :status-config="statusOptions[item.task_status]"
                :status="item.task_status"
              />
            </td>

            <td>{{ getEditorCount(item) }}</td>
            <td>{{ item.parsed_devices || "---" }}</td>
            <td v-html="getDeadline(item)" />

            <td>
              <p class="text--caption">Creation Date</p>
              <p class="text--caption--demi">
                {{ item.parsed_created_at || "---" }}
              </p>

              <div v-if="item.row_parsed_completed_at" class="mt-2">
                <p class="text--caption">Completed at</p>
                <p class="text--caption--demi">
                  {{ item.row_parsed_completed_at || "---" }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
</template>

<script>
import { mapState } from 'vuex'
import { Card } from '@unbabel/ui'

import StatusChip from '@/components/StatusChip'
import CopyID from '../CopyID'

import { statusOptions } from '@/data/status'

export default {
  name: 'TranslationTasksPreview',
  components: {
    Card,
    StatusChip,
    CopyID
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statusOptions
    }
  },
  computed: {
    ...mapState('tasks', ['loading', 'translationTasks']),
    loadingTasks () {
      return !!this.loading[this.translation.request.uid]
    },
    tasks () {
      return this.translationTasks[this.translation.request.uid] ?? []
    }
  },
  methods: {
    getDeadline (item) {
      const timeElapsed = item.time_elapsed ?? item.time_taken

      let deadlineClass = 'text--bold'
      if (item.exceeded_sla) {
        deadlineClass += ' text--error'
      }

      return `<span class="${deadlineClass}">${timeElapsed}</span> / ${item.parsed_timeout_seconds}`
    },
    getEditorCount (item) {
      return item.translation_records?.length || 0
    },
    onRowClicked (item) {
      this.$router.push({
        name: 'Flexible Task',
        params: { id: this.$route.params.id, taskId: item.id },
        query: this.$route.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Card__body {
  padding: 0 !important;
}

table {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    padding: 0 0 0 calc(#{$base-padding} * 1.5);
    text-align: left;
    vertical-align: middle;
    padding: $base-padding;
  }

  tr {
    box-shadow: inset 0px -1px 0px 0px $un-n200;
  }

  tbody tr:hover {
    background-color: $un-n50;
    cursor: pointer;
  }

  tbody tr:last-child {
    box-shadow: none;
  }

  th:last-child,
  td:last-child {
    padding-right: calc(#{$base-padding} * 1.5) !important;
  }

  thead th {
    color: $un-n900;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  td {
    cursor: pointer;
    color: $un-n900;
    font-family: $primary-font;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.21px;
    text-align: left;
  }

  td:first-child {
    font-weight: 600;
  }

  .c-Tooltip__messageContainer {
    margin-left: 40px !important;
  }
}
</style>
