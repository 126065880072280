<template>
  <Card title="Queue">
    <div
      v-if="task.category_id"
      slot="header-actions"
      class="c-QueueDetails__cardActions"
    >
      <a :href="tarkinCategoryUrl" target="_blank" rel="noopener noreferrer">
        <Button
          label="Category"
          button-type="is-outlined"
          icon="external-hyperlink"
        />
      </a>
    </div>

    <div slot="body" class="c-QueueDetails__cardBody">
      <CardBodyLayout>
        <tr>
          <td>
            <p>Deadline</p>
            <div class="text--body-2 text--light">
              <span :class="{ 'text--error': task.exceeded_sla }">
                {{ task.time_elapsed || task.time_taken }}
              </span>
               / {{ task.parsed_timeout_seconds }}
            </div>
          </td>

          <td>
            <p>Priority</p>
            <div class="text--body-2 text--light">{{ task.priority || "---" }}</div>
          </td>

          <td>
            <p>Expertise Level</p>
            <div class="text--body-2 text--light">
              {{ task.expertise_level || "No level set" }}
              <span
                v-if="task.expertise_level !== null && task.time_to_degredation"
              >
                ({{ task.time_to_degredation }} to degradation)
              </span>
            </div>
          </td>
        </tr>
      </CardBodyLayout>
    </div>
  </Card>
</template>

<script>
import { Card, Button } from '@unbabel/ui'
import CardBodyLayout from '@/components/CardBodyLayout'

export default {
  name: 'QueueDetails',
  components: {
    Card,
    Button,
    CardBodyLayout
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    tarkinCategoryUrl () {
      return `${process.env.VUE_APP_TARKIN_BASE_URL}/category/${this.task.category_id}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Card__header {
  min-height: 65px;
}

.c-QueueDetails {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

td {
  p {
    margin-bottom: 0 !important;
  }

  div {
    @extend .mt-1, .text--body-2, .text--light;
    font-size: 0.875rem;
  }
}
</style>
