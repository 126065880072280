import axios from 'axios'
import {
  retrieveFromCustomerApiCache,
  storeToCustomerApiCache
} from '@/utils/customerApiCache'
import {
  parseSearchResults,
  parseTranslationDetails
} from '@/utils/parsingUtils'
import {
  generateRequestUrlParams
} from '@/utils/urlUtils'
import { FILTERS_DICT, GLOBAL_MUTATIONS, TASK_TYPE, EXECUTION_STEPS, SYSTEM_NAME } from '@/data/enum'
import router from '@/router'

const BASE_URL = process.env.VUE_APP_API_URL
const UNBABEL_API = process.env.VUE_APP_UNBABEL_API_URL
const TRANTOR_BASE_URL = process.env.VUE_APP_TRANTOR_URL
const MAX_TASK_LIMIT_FETCH_NUGGETS = process.env.VUE_APP_MAX_TASK_LIMIT_FETCH_NUGGETS
const MAX_LIMIT_QUERY_TARKIN_ITEMS = process.env.VUE_APP_MAX_LIMIT_QUERY_TARKIN_ITEMS
const TRANSLATION_API_BASE_URL = process.env.VUE_APP_TRANSLATION_API_OS_URL
const searchByTaskId = {}
const searchByTranslationRecordId = {}

const NOT_FOUND_ERROR = 'error reading trace from DB'

function __getMessageDetails (payload) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/view`
  return axios.get(requestUrl)
}

function getTranslationDetails (payload, commit) {
  const generalPromisesArray = []
  const generalPromisesIndex = []

  const isMessageDetailsView = !payload.taskId && !payload.translationRecordId
  const isTaskDetailsView = payload.taskId && !payload.translationRecordId
  const isTrDetailsView = payload.taskId && payload.translationRecordId

  // set all loaders
  commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
  commit(GLOBAL_MUTATIONS.UPDATE_HT_TASKS_LOADING, true)
  commit(GLOBAL_MUTATIONS.UPDATE_NUGGETS_LOADING, true)

  // get msg details first (Flowrunner)
  return API.__getMessageDetails(payload).then(res => {
    // Flowrunner msg details
    const msgDetails = res.data
    commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, parseTranslationDetails(msgDetails))

    // This is the only way we have, for now, to know if there is a translation
    const uidPattern = /^[a-zA-Z0-9]+$/
    if (uidPattern.test(msgDetails.uid)) {
      generalPromisesIndex.push('translation')
      generalPromisesArray.push(API.fetchFromInternalTranslationsAPI({
        type: 'get',
        customerId: msgDetails.customer.username,
        translationUID: msgDetails.uid
      }))
    }

    // fetch project if there's a x-unbabel-project-id tag
    if (msgDetails.trace?.workflow_execution?.request_tags?.['x-unbabel-project-id']?.[0]) {
      generalPromisesIndex.push('projects')
      const projectId = msgDetails.trace.workflow_execution.request_tags['x-unbabel-project-id'][0]
      generalPromisesArray.push(API.fetchFromProjectsAPI({
        url: `customers/${msgDetails.customer.username}/projects/${projectId}`,
        type: 'get'
      }).then((project) => { msgDetails.project_name = project.name }))
    }

    // fetch canonical_name if it doesn't exist (customer API)
    if (msgDetails.customer?.username && !msgDetails.customer?.canonical_name) {
      generalPromisesIndex.push('customer')
      generalPromisesArray.push(API.fetchFromCustomerAPI({
        url: `v4/customers/${msgDetails.customer.username}`,
        type: 'get',
        cacheType: 'variable',
        customerId: msgDetails.customer.username,
        dataType: 'customer'
      }))
    }

    // main message details view only
    if (isMessageDetailsView) {
      // hide global loader to unlock interface when loading main message details
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)

      // get tasks (Tarkin)
      generalPromisesIndex.push('tasks')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/task',
        queryParams: {
          filter: `[{"field":"group_id","op":"==","value":"${payload.uid}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))

      // get translation records (Tarkin)
      generalPromisesIndex.push('translation_records')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/translation_records',
        queryParams: {
          filter: `[{"field":"group_id","op":"==","value":"${payload.uid}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))
    }

    // task details view only
    if (isTaskDetailsView) {
      // get single task (Tarkin)
      generalPromisesIndex.push('tasks')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/task',
        queryParams: {
          filter: `[{"field":"id","op":"==","value":"${payload.taskId}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))

      // get translation records for the task (Tarkin)
      generalPromisesIndex.push('translation_records')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/translation_records',
        queryParams: {
          filter: `[{"field":"task_id","op":"==","value":"${payload.taskId}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))

      // get task unstranslated nuggets
      generalPromisesIndex.push('untranslated_task_nuggets')
      generalPromisesArray.push(API.fetchFromTarkinAPI({
        url: `v1/task/${payload.taskId}/nuggets?untranslated=True`,
        type: 'get'
      }))

      // get task translated nuggets
      generalPromisesIndex.push('translated_task_nuggets')
      generalPromisesArray.push(API.fetchFromTarkinAPI({
        url: `v1/task/${payload.taskId}/nuggets`,
        type: 'get'
      }))
    }

    // translation records details view only
    if (isTrDetailsView) {
      // get single task (Tarkin)
      generalPromisesIndex.push('tasks')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/task',
        queryParams: {
          filter: `[{"field":"id","op":"==","value":"${payload.taskId}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))

      // get single translation record (Tarkin)
      generalPromisesIndex.push('translation_records')
      generalPromisesArray.push(API.recursiveFetchFromTarkinAPI({
        url: 'v1/translation_records',
        queryParams: {
          filter: `[{"field":"id","op":"==","value":"${payload.translationRecordId}"}]`
        },
        type: 'get'
      }, 1, MAX_LIMIT_QUERY_TARKIN_ITEMS, { items: [] }))

      // get translation record untranslated nuggets
      generalPromisesIndex.push('untranslated_task_nuggets')
      generalPromisesArray.push(API.fetchFromTarkinAPI({
        url: `v1/task/${payload.taskId}/nuggets?untranslated=True`,
        type: 'get'
      }))

      // get translation record translated nuggets
      generalPromisesIndex.push('translated_translation_records_nuggets')
      generalPromisesArray.push(API.fetchFromTarkinAPI({
        url: `v1/translation_records/${payload.translationRecordId}/nuggets`,
        type: 'get'
      }))
    }

    return Promise.allSettled(generalPromisesArray).then(detailsData => {
      // make sure we are not using the tasks from Flowrunner
      if (msgDetails.human_translation_details?.tasks) {
        delete msgDetails.human_translation_details.tasks
      }

      msgDetails.customerApi = {}
      msgDetails.tarkinApi = {}
      const HTExecutions = msgDetails.trace?.workflow_execution?.step_executions?.filter(step => step.step_id.includes(EXECUTION_STEPS.HT))

      generalPromisesIndex.forEach((area, index) => {
        if (!detailsData[index]) return

        switch (area) {
          // get tasks (Tarkin)
          case 'tasks':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.tarkinApi.tasks = detailsData[index].value.items

              // append flowrunner information
              if (HTExecutions) {
                msgDetails.tarkinApi.tasks.forEach(t => {
                  HTExecutions.some(execution => {
                    const flowrunnerTask = execution.output.data?.translation_tasks?.find(task => task.id === t.id)
                    if (flowrunnerTask) {
                      t.flowrunnerTaskDetails = flowrunnerTask
                      return true
                    }
                    return false
                  })
                })
              }

              if (!msgDetails.human_translation_details) {
                msgDetails.human_translation_details = {}
              }

              msgDetails.human_translation_details.tasks = msgDetails.tarkinApi.tasks

              // Sort tasks by created_at
              msgDetails.human_translation_details.tasks.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
            } else {
              // if there was an error, flag it to be displayed on the HtSection
              if (!msgDetails.human_translation_details) {
                msgDetails.human_translation_details = {}
              }
              msgDetails.human_translation_details.tasksLoadingError = true
            }
            break
          // get translation records (Tarkin)
          case 'translation_records':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.tarkinApi.translation_records = detailsData[index].value.items
            } else {
              // if there was an error, flag it to be displayed on the TranslationRecordListSection
              if (!msgDetails.human_translation_details) {
                msgDetails.human_translation_details = {}
              }
              msgDetails.human_translation_details.translationRecordsLoadingError = true
            }
            break
          case 'translation':
            if (detailsData[index].value?.file_processing_info) {
              msgDetails.file_processing_information = detailsData[index].value.file_processing_info
              msgDetails.file_processing_information.status = detailsData[index].value.internal_status
            }
            break
          // fetch canonical_name if it doesn't exist (customer API)
          case 'customer':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.customerApi.customer = detailsData[index].value
              msgDetails.customer.canonical_name = detailsData[index].value.canonical_name
            }
            break
          // get task nuggets (Tarkin - task details view only)
          case 'untranslated_task_nuggets':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.tarkinApi.task_source_nuggets = detailsData[index].value
            }
            break
          // get task nuggets (Tarkin - task details view only)
          case 'translated_task_nuggets':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.tarkinApi.task_target_nuggets = detailsData[index].value
            }
            break
          // get translation record nuggets (Tarkin - translation records details view only)
          case 'translated_translation_records_nuggets':
            if (detailsData[index].status === 'fulfilled' && detailsData[index].value) {
              msgDetails.tarkinApi.translation_records_target_nuggets = detailsData[index].value
            }
            break
          default:
            break
        }
      })

      // assign Tarkin task nuggets to their repective tasks
      if (msgDetails.human_translation_details?.tasks?.length) {
        const taskIndex = msgDetails.human_translation_details.tasks.findIndex(item => item.id === payload.taskId)

        // add editor nuggets to task
        if (taskIndex >= 0) {
          msgDetails.human_translation_details.tasks[taskIndex].source_nuggets = msgDetails.tarkinApi?.task_source_nuggets?.target_nuggets || []
          msgDetails.human_translation_details.tasks[taskIndex].target_nuggets = msgDetails.tarkinApi?.task_target_nuggets?.target_nuggets || []
        }
      }

      // assign translation records to their repective tasks
      if (msgDetails.tarkinApi?.tasks?.length && msgDetails.tarkinApi?.translation_records?.length) {
        msgDetails.tarkinApi.tasks.forEach((task, index) => {
          const records = msgDetails.tarkinApi.translation_records.filter(item => item.task.id === task.id)

          if (records && records.length) {
            msgDetails.tarkinApi.tasks[index].translation_records = records

            // add editor nuggets to record
            const trIndex = records.findIndex(item => item.id === payload.translationRecordId)
            if (trIndex >= 0) {
              msgDetails.tarkinApi.tasks[index].translation_records[trIndex].source_nuggets = msgDetails.tarkinApi?.task_source_nuggets?.target_nuggets || []
              msgDetails.tarkinApi.tasks[index].translation_records[trIndex].target_nuggets = msgDetails.tarkinApi?.translation_records_target_nuggets?.target_nuggets || []
            }
          }
        })
      }

      // hide global loader for Task Details View or Translation Records Details View
      if (isTaskDetailsView || isTrDetailsView) {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      }

      // hide loader fot HT tasks
      commit(GLOBAL_MUTATIONS.UPDATE_HT_TASKS_LOADING, false)
      commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, parseTranslationDetails(msgDetails))

      // for message details view AND NOT task details view AND NOT translation records details view
      // fetch the nuggets per taskId, only for "map_reduced_paid" dequeued tasks (completed paid tasks only)
      // so that we can display the nuggets per column on the nuggetsLayout component
      //
      // NOTE: we don't need the SENIOR REVIEW step nuggets because that's already included on the "human_translation_details"
      // response from Controlplane/Flowrunner as the final HT translation nuggets
      if (isMessageDetailsView &&
      msgDetails.human_translation_details?.tasks?.length &&
      msgDetails.human_translation_details?.tasks?.length < MAX_TASK_LIMIT_FETCH_NUGGETS) {
        const nuggetsPromisesArray = []
        const nuggetsPromisesInfo = []

        // fetch TRANSLATED NUGGETS for "map_reduced_paid" dequeued tasks (completed paid tasks only)...
        // and now also for "review" ones
        msgDetails.human_translation_details.tasks.forEach((task, i) => {
          if ([TASK_TYPE.MAP_REDUCE_PAID, TASK_TYPE.REVIEW].some((type) => type === task.type)) {
            let taskType = task.type
            if (task.type) {
              switch (task.type) {
                case TASK_TYPE.MAP_REDUCE_PAID:
                  taskType = 'Post Edition'
                  break
                case TASK_TYPE.SEGMENT_CURATION:
                  taskType = 'TM Curation'
                  break
                case TASK_TYPE.REVIEW:
                  taskType = 'Revision'
                  break
                default:
                  taskType = task.type
                  break
              }
            }

            nuggetsPromisesInfo.push({
              inProgress: task.in_progress,
              taskType: taskType,
              flowrunnerTaskDetails: task.flowrunnerTaskDetails
            })

            nuggetsPromisesArray.push(API.fetchFromTarkinAPI({
              url: `v1/task/${task.id}/nuggets`,
              type: 'get'
            }))
          }
        })

        return Promise.allSettled(nuggetsPromisesArray).then(nuggetsData => {
          let nuggetsBlock = []
          let flowrunnerSegment

          // create array of nuggets based on the responses
          // and fetch the blocking reason from flowrunner response
          nuggetsData
            .filter(item => item.status === 'fulfilled' && item.value?.target_nuggets?.length)
            .forEach((item, i) => {
              const { flowrunnerTaskDetails, ...nuggetsInfo } = nuggetsPromisesInfo[i]
              const targetNuggets = item.value.target_nuggets.map(item => {
                if (!item.blocking_reason) {
                  flowrunnerSegment = flowrunnerTaskDetails?.translated_segments?.[item.doc_position]
                  if (flowrunnerSegment?.segment?.id === item.core_nugget_id) {
                    return { blocking_reason: flowrunnerSegment?.blocking_reason, ...item, ...nuggetsInfo }
                  }
                }

                return { ...item, ...nuggetsInfo }
              })

              nuggetsBlock.push(...targetNuggets)
            })

          // sort by doc_position
          nuggetsBlock = nuggetsBlock.sort((a, b) => a.doc_position - b.doc_position)

          // add missing nuggets to the nuggets block (always compare to the full count of nuggets in the translation)
          // so that we can add FAILED requests and meet the nuggets count of the full translation...

          while (nuggetsBlock.length > 0) {
            const htNuggets = []
            msgDetails.machine_translation_details[0].nuggets.forEach((item, i) => {
              // Find nuggets based on their ID
              const nuggetIndex = nuggetsBlock.findIndex(nugget => nugget.core_nugget_id === item.id)
              if (nuggetIndex >= 0) {
                htNuggets.push(nuggetsBlock[nuggetIndex])
              } else {
                htNuggets.push({ hasFailed: true })
              }
              // Remove pushed nugget from nuggetsBlock
              nuggetsBlock.splice(nuggetIndex, 1)
            })

            // Set nuggets array with all HT nuggets from Tarkin sorted by tasks
            // and position given that machine_translation_details[0].nuggets has nuggets sorted
            // by position
            if (msgDetails.human_translation_details.content.nuggets === undefined) {
              msgDetails.human_translation_details.content.nuggets = []
            }
            msgDetails.human_translation_details.content.nuggets.push(htNuggets)
          }

          const parsedMsgDetails = parseTranslationDetails(msgDetails)

          // hide nuggets loader
          commit(GLOBAL_MUTATIONS.UPDATE_NUGGETS_LOADING, false)
          commit(GLOBAL_MUTATIONS.UPDATE_MESSAGE_DETAILS, parsedMsgDetails)

          return Promise.resolve(parsedMsgDetails)
        })
      } else {
        // hide nuggets loader
        commit(GLOBAL_MUTATIONS.UPDATE_NUGGETS_LOADING, false)

        const parsedMsgDetails = parseTranslationDetails(msgDetails)
        return Promise.resolve(parsedMsgDetails)
      }
    })
  }).catch(err => {
    commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
    commit(GLOBAL_MUTATIONS.UPDATE_HT_TASKS_LOADING, false)
    commit(GLOBAL_MUTATIONS.UPDATE_NUGGETS_LOADING, false)

    // if translation not found, look for it in flex
    if (err?.response?.data?.includes(NOT_FOUND_ERROR)) {
      router.push({ name: 'Flexible', params: { id: payload.uid } })
    } else {
      return Promise.reject(new Error(`Error fetching Translation Details ::: ${payload.uid} - ${err}`))
    }
  })
}

async function searchTranslations (filters, searchParams) {
  // assign to a new object to prevent changing the state FILTER params directly
  const searchFilters = Object.assign({}, filters)

  // convert TASK_ID to UID before performing a search on Xray, if needed
  if (searchFilters[FILTERS_DICT.TASK_ID] && searchFilters[FILTERS_DICT.TASK_ID].length) {
    // only call the endpoint if we don't have the UID for the task
    if (!searchByTaskId[searchFilters[FILTERS_DICT.TASK_ID]] || searchByTaskId[searchFilters[FILTERS_DICT.TASK_ID]].length === 0) {
      const res = await API.fetchFromTarkinAPI({
        url: `v1/task?page=1&page_size=100&filter=[{"field":"id","op":"==","value":"${searchFilters[FILTERS_DICT.TASK_ID]}"}]`,
        type: 'get'
      })

      if (!res.items || (res.items && res.items.length === 0)) {
        throw new Error(`No translations found by Task ID: ${searchFilters[FILTERS_DICT.TASK_ID]}`)
      }

      if (res.items && res.items.length > 1) {
        throw new Error(`More than 1 translation found by Task ID. Something is wrong: ${searchFilters[FILTERS_DICT.TASK_ID]}`)
      }

      // store to prevent future calls to the endpoint if needed
      searchByTaskId[searchFilters[FILTERS_DICT.TASK_ID]] = res.items[0].group_id
    }

    searchFilters[FILTERS_DICT.UID] = searchByTaskId[searchFilters[FILTERS_DICT.TASK_ID]]
    delete searchFilters[FILTERS_DICT.TASK_ID]

    // convert TRANSLATION_RECORD_ID to UID before performing a search on Xray, if needed
  } else if (searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID] && searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID].length) {
    // only call the endpoint if we don't have the UID for the translation record
    if (!searchByTranslationRecordId[searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]] || searchByTranslationRecordId[searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]].length === 0) {
      const res = await API.fetchFromTarkinAPI({
        url: `v1/translation_records?page=1&page_size=100&filter=[{"field":"id","op":"==","value":"${searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]}"}]`,
        type: 'get'
      })

      if (!res.items || (res.items && res.items.length === 0)) {
        throw new Error(`No translations found by translation record ID: ${searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]}`)
      }

      if (res.items && res.items.length > 1) {
        throw new Error(`More than 1 translation found by translation record ID. Something is wrong: ${searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]}`)
      }

      if (res.items[0] && !res.items[0].task) {
        throw new Error(`No task found for that translation record ID. Something is wrong: ${searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]}`)
      }

      // store to prevent future calls to the endpoint if needed
      searchByTranslationRecordId[searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]] = res.items[0].task.group_id
    }

    searchFilters[FILTERS_DICT.UID] = searchByTranslationRecordId[searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]]
    delete searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]
  }

  const urlParams = generateRequestUrlParams(searchFilters, searchParams)
  const requestUrl = `${BASE_URL}/messages${urlParams}`
  return axios.get(requestUrl).then((res) => {
    res.data.messages = parseSearchResults(res.data.messages)
    return res.data
  })
}

/**
 *  Fetch data from customer API
 *  @param {String} url - path to the customer API ex: "v4/customers/"
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} cacheType - cache type to be checked @values variable, localStorage, sessionStorage
 *  @param {String} customerId - primary customer identifier
 *  @param {String} dataType - cache data type ex: "customer"
 *  @param {Boolean} preventReadingFromCache - wether info should read from cache or not
 *  @param {Boolean} preventStoreInCache - wether info should be stored in cache or not
 */
function fetchFromCustomerAPI (payload) {
  if (payload.cacheType && payload.customerId && payload.dataType && !payload.preventReadingFromCache) {
    const cachedData = retrieveFromCustomerApiCache(payload.cacheType, payload.customerId, payload.dataType)
    if (cachedData) {
      return Promise.resolve(cachedData)
    }
  }

  const requestUrl = `${BASE_URL}/customerapi/${payload.url}`
  return axios[payload.type](requestUrl).then((res) => {
    if (payload.cacheType && payload.customerId && payload.dataType && !payload.preventStoreInCache) {
      storeToCustomerApiCache(payload.cacheType, payload.customerId, payload.dataType, res.data)
    }
    return res.data
  })
}

/**
 *  Fetch data from Tarkin API
 *  @param {String} url - path to the customer API ex: "/tarkin/"
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} data - XHR request body payload
 */
function fetchFromTarkinAPI (payload) {
  const requestUrl = `${BASE_URL}/tarkin/api/${payload.url}`
  return axios[payload.type](requestUrl, payload.data).then(res => res.data)
}

/**
 *  Get all pages of data from Tarkin API
 *  @param {String} url - path to the Tarkin API ex: "/tarkin/"
 *  @param {Array} queryParams - array of query params to be added
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} data - XHR request body payload
 */
async function recursiveFetchFromTarkinAPI (payload, page, pageSize, data) {
  let queryParams

  if (payload.queryParams) {
    queryParams = '&' + Object.keys(payload.queryParams).map(key => `${key}=${payload.queryParams[`${key}`]}`).join('&')
  }

  const newPayload = {
    url: `${payload.url}?page=${page}&page_size=${pageSize}&${queryParams}`,
    type: 'get'
  }

  const res = await API.fetchFromTarkinAPI(newPayload)

  if (!res.page_size) {
    return Promise.reject(new Error('error fetching recursive data from Tarkin'))
  }

  data.items = data.items.concat(res.items)

  if (data.items?.length < res.total_items) {
    return recursiveFetchFromTarkinAPI(payload, ++page, pageSize, data)
  } else {
    return Promise.resolve(data)
  }
}

/**
 *  Post batches to Tarkin API
 *  @param {Object} payload - XHR request payload
 *  @param {Integer} index - XHR request data current element index
 *  @param {Integer} pageSize - XHR request data page size
 */
async function recursivePostToTarkinAPI (payload, index, pageSize) {
  const newPayload = {
    url: `${payload.url}`,
    type: 'post',
    data: { ...payload.data },
    batchesField: payload.batchesField
  }

  newPayload.data[`${newPayload.batchesField}`] = payload.data[`${payload.batchesField}`].slice(index, index + pageSize)

  const res = await API.fetchFromTarkinAPI(newPayload)

  if (res.error) {
    return Promise.reject(new Error('error posting recursive data to Tarkin'))
  }

  if (payload.data[`${payload.batchesField}`].length > index + pageSize) {
    return recursivePostToTarkinAPI(payload, index + pageSize, pageSize)
  } else {
    return Promise.resolve(payload.data)
  }
}

/**
 *  Fetch data from Core API
 *  @param {String} url - path to the Core API ex: "/core/"
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} data - XHR request body payload
 */
function fetchFromCoreAPI (payload) {
  const requestUrl = `${BASE_URL}/core/${payload.url}`
  return axios[payload.type](requestUrl, payload.data).then(res => res.data)
}

/**
 *  Fetch data from Projects API
 *  @param {String} url - path to the Core API ex: "/projects"
 *  @param {String} customerId - customer ID in search
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} data - XHR request body payload
 */
function fetchFromProjectsAPI (payload) {
  const requestUrl = `${UNBABEL_API}/projects/v0/${payload.url}`
  return axios[payload.type](requestUrl, payload.data).then(res => res.data)
}

/**
 *  Fetch data from Internal Translations API OS
 *  @param {String} url - path to the Translations API ex: "/core/"
 *  @param {String} type - XHR request type @values GET, POST
 *  @param {String} data - XHR request body payload
 */
function fetchFromInternalTranslationsAPI (payload) {
  const requestUrl = `${TRANSLATION_API_BASE_URL}/v1/customers/${payload.customerId}/internal/translations/${payload.translationUID}`
  return axios[payload.type](requestUrl, payload.data).then(res => res.data)
}

function sendToMarkupAligner (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/send_to_markup_aligner`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function restartPipeline (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/restart`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function retryPipeline (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/retry`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function deliverMachineTranslation (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/deliver_machine_translation`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function cancelHumanTranslation (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/cancel_translation`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function pauseHumanTranslation (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/pause_human_translation`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function resumeHumanTranslation (payload, commit) {
  const requestUrl = `${BASE_URL}/messages/${payload.uid}/resume_human_translation`
  return axios.post(requestUrl, payload).then(() => API.getTranslationDetails(payload, commit))
}

function fetchBrandInformation (searchTerm) {
  const requestUrl = `${BASE_URL}/translation_profiles?translation_profile_name=${searchTerm}`
  return axios.get(requestUrl).then((res) => res.data)
}

function downloadSearchResults (payload) {
  return axios.post(`${TRANTOR_BASE_URL}/gateway/notifications/webhook/xray/download-report/`, payload)
}

function tarkinCancelTask (payload, commit, actor) {
  const headers = __addAuditHeaders(actor)
  const requestUrl = `${BASE_URL}/tarkin/v2/task_management/translation_tasks:cancel`
  return axios.post(requestUrl, payload, { headers }).then((res) => { API.getTranslationDetails(payload, commit); return res })
}

function tarkinClearSkips (payload, actor) {
  const headers = __addAuditHeaders(actor)
  return axios.post(`${BASE_URL}/tarkin/v2/task_management/translation_tasks:clear_skips`, payload, { headers })
}

function tarkinForceDegradation (payload, actor) {
  const headers = __addAuditHeaders(actor)
  return axios.post(`${BASE_URL}/tarkin/v2/task_management/translation_tasks:degrade`, payload, { headers })
}

function tarkinAssignToEditor (payload, actor) {
  const headers = __addAuditHeaders(actor)
  return axios.post(`${BASE_URL}/tarkin/v2/task_management/translation_tasks:assign`, payload, { headers })
}

function tarkinReassignTask (payload, actor) {
  const headers = __addAuditHeaders(actor)
  return axios.post(`${BASE_URL}/tarkin/v2/task_assignment/translation_tasks:reassign_task`, payload, { headers })
}

function __addAuditHeaders (actor) {
  const headers = {}

  // Add the x-system header from the global SYSTEM_NAME variable
  headers['x-system'] = SYSTEM_NAME

  // Add the x-actor header if actor is not null or empty
  if (actor?.trim()) {
    headers['x-actor'] = actor
  }

  return headers
}

export const API = {
  __getMessageDetails,
  __addAuditHeaders,
  searchTranslations,
  getTranslationDetails,
  fetchFromCustomerAPI,
  fetchFromTarkinAPI,
  recursiveFetchFromTarkinAPI,
  recursivePostToTarkinAPI,
  fetchFromCoreAPI,
  fetchFromProjectsAPI,
  sendToMarkupAligner,
  restartPipeline,
  retryPipeline,
  deliverMachineTranslation,
  cancelHumanTranslation,
  pauseHumanTranslation,
  resumeHumanTranslation,
  fetchBrandInformation,
  downloadSearchResults,
  tarkinClearSkips,
  tarkinForceDegradation,
  tarkinAssignToEditor,
  tarkinReassignTask,
  tarkinCancelTask,
  fetchFromInternalTranslationsAPI
}
