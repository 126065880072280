/* eslint-disable camelcase */
import {
  OVERALL_STATUS,
  TASK_TYPE,
  ARCH_STATUS,
  STEP_STATUS,
  TAG_PURPOSE,
  EXECUTION_STEPS,
  MODULE,
  HT_TASK_STATUS
} from '@/data/enum'
import { languageOptions } from '@/data/languages'
import {
  calcDatesDiff,
  parseDuration,
  formatToXrayDateDisplay
} from '@/utils/dateUtils'

export function __stripHtmlTags (string) {
  // remove general html tags
  return string.replace(/<(?!!)[^>]*>?/gm, '')
}

export function parseSearchResults (searchList) {
  if (searchList && searchList.length) {
    const parsedSearchList = searchList.map((item, index) => {
      const listItem = Object.assign({}, item)
      listItem.isIdCopied = false

      if (item.completion_date && item.completion_date !== '0001-01-01T00:00:00Z') {
        const datesDiff = calcDatesDiff(item.creation_date, item.completion_date)
        listItem.parsed_creation_date = datesDiff.parsedStartDate
        listItem.utc_creation_date = datesDiff.utcStartDate
        listItem.parsed_completion_date = datesDiff.parsedEndDate
        listItem.utc_completion_date = datesDiff.utcEndDate

        listItem.time_spent = datesDiff.diffElapsed
        listItem.full_time_spent = datesDiff.diffFullTime
      } else {
        listItem.parsed_creation_date = formatToXrayDateDisplay(item.creation_date)
        listItem.utc_creation_date = formatToXrayDateDisplay(item.creation_date, true)

        if (listItem.status === OVERALL_STATUS.FAILED_STATUS ||
          listItem.status === OVERALL_STATUS.HT_CANCEL_STATUS ||
          listItem.status === OVERALL_STATUS.CANCELED_STATUS) {
          listItem.time_spent = 'None'
        } else {
          const datesDiff = calcDatesDiff(new Date(item.creation_date), new Date())
          // time in progress
          listItem.time_spent = datesDiff.diffElapsed
          listItem.full_time_spent = datesDiff.diffFullTime
        }
      }

      if (item.deadline_date) {
        listItem.parsed_deadline_date = formatToXrayDateDisplay(item.deadline_date)
        listItem.utc_parsed_deadline_date = formatToXrayDateDisplay(item.deadline_date, true)
      }

      if (listItem.original_text && listItem.original_text.length > 0) {
        listItem.original_text = __stripHtmlTags(listItem.original_text)

        if (listItem.original_text.length > 160) {
          listItem.original_text = `${listItem.original_text.substring(0, 160)}…`
        }
      }
      if (listItem.translation && listItem.translation.length > 0) {
        listItem.translation = __stripHtmlTags(listItem.translation)

        if (listItem.translation.length > 160) {
          listItem.translation = `${listItem.translation.substring(0, 160)}…`
        }
      }

      return listItem
    })

    return parsedSearchList
  } else if (!searchList) {
    return []
  }

  return searchList
}

export function parseSnakeToHuman (value) {
  return value.toLowerCase().split('_').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
}

/* FLOW DIAGRAM PHASES */
export function __handlePhasesDataForFlowDiagram (execution, phasesProperties) {
  const { coreFallback, steps } = phasesProperties

  const parsedPhases = steps.map((step) => {
    const { id, module_name, module_config } = step
    const item = execution.find(executionStep => executionStep.step_id === id) ?? {}

    item.name = module_name
    item.parsedName = parseSnakeToHuman(module_name)

    if (!item.status) {
      item.status = __assignFlowStatusByPhaseDates(item)
    }

    // generate phases information
    switch (item.status) {
      case STEP_STATUS.PENDING:
        item.info = item.info ?? `${item.parsedName} in progress...`
        break
      case STEP_STATUS.FAILED:
        item.info = item.info ?? `${item.parsedName} failed`
        break
      case STEP_STATUS.SKIPPED:
      case STEP_STATUS.CANCELLED:
        item.info = item.info ?? `${item.parsedName} was ${item.status}`
        break
    }

    switch (module_name) {
      case MODULE.REBUILD:
        if (item.status === STEP_STATUS.PENDING) {
          item.info = 'Rebuilding...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.info = 'Rebuild failed'
        }
        break
      case MODULE.HT:
        // if all tasks were skipped override phase as SKIPPED
        if (item.status === STEP_STATUS.COMPLETED) {
          const tasksSkipped = item.output?.transient_data?.all_tasks_skipped
          if (tasksSkipped) {
            item.status = STEP_STATUS.SKIPPED
            item.info = 'All tasks were skipped'
          }
        }
        break
      case MODULE.DELIVERY:
      case MODULE.METERING:
        if (item.status === STEP_STATUS.PENDING) {
          item.info = 'Delivering...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.info = 'Deliver failed'
        }
        break
      case MODULE.TM_UPDATE:
        if (item.status === STEP_STATUS.PENDING) {
          item.info = 'Updating TM...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.info = 'TM update failed'
        } else {
        }
        break
    }

    // updating FALLBACKS interdependency with flow phases
    if (coreFallback && [MODULE.MT, MODULE.HT, MODULE.REVIEW, MODULE.REBUILD, MODULE.TM_UPDATE].includes(item.name)) {
      item.status = STEP_STATUS.SKIPPED
      item.info = 'Core Fallback'
    }

    if (item.started_at) {
      item.parsed_started_at = formatToXrayDateDisplay(item.started_at)
      item.utc_parsed_started_at = formatToXrayDateDisplay(item.started_at, true)
    }

    if (item.completed_at) {
      item.parsed_completed_at = formatToXrayDateDisplay(item.completed_at)
      item.utc_parsed_completed_at = formatToXrayDateDisplay(item.completed_at, true)
    }

    // Get module data from step
    let configs = {}
    if (module_config?.length > 0 && Array.isArray(module_config[0].Value)) {
      configs = module_config[0].Value.reduce((acc, obj) => ({ ...acc, [obj.Key]: obj.Value }), {})
    }

    return {
      ...configs,
      ...item,
      outputFile: __retrieveOutputXliff(item)
    }
  })

  return parsedPhases
}

/**
 * @deprecated Support for old v1 phases
 */
export function __handleV1PhasesData (phasesData, phasesProperties) {
  return phasesData.map((item) => {
    // assign statuses to each phase, according to the data (parse METADATA first before CANCEL!)
    if (item?.metadata?.skip_reason) {
      item.status = STEP_STATUS.SKIPPED
      phasesProperties.skippedHt = true
    } else if (item?.metadata?.has_failed) {
      item.status = STEP_STATUS.FAILED
    } else if (item?.metadata?.has_upgraded_to_ht) {
      item.info = 'Upgrade to HT'
    } else if (item?.metadata?.has_timeout) {
      item.status = STEP_STATUS.SKIPPED
      item.info = 'Timeout'
    } else if (item?.metadata?.was_sent_to_markup_aligner) {
      item.info = 'Markup Aligner'
      item.status = __assignFlowStatusByPhaseDates(item)
    } else if (item.name === 'HT' && item.end_phase === OVERALL_STATUS.HT_CANCEL_STATUS) {
      item.status = STEP_STATUS.CANCELED
    } else if (item?.metadata?.was_canceled === true) {
      item.status = STEP_STATUS.CANCELED
    }

    if (!item.status) {
      item.status = __assignFlowStatusByPhaseDates(item)
    }

    // updating FALLBACKS interdependency with flow phases
    if (phasesProperties.coreFallback && ['MT', 'HT', 'REVIEW', 'REBUILD', 'TM_UPDATE'].includes(item.name)) {
      item.status = STEP_STATUS.SKIPPED.SKIPPED
      item.info = 'Core Fallback'
    }

    // updating HT_SKIP interdependency with flow phases
    if (phasesProperties.skippedHt && (item.name === 'REVIEW' || item.name === 'REBUILD' || item.name === 'TM_UPDATE')) {
      item.status = STEP_STATUS.SKIPPED.SKIPPED
      item.info = 'HT Skipped'
    }

    // generate phases titles
    switch (item.name) {
      case 'INIT':
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'creating...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'Creation Failed'
        } else {
          item.parsedName = 'created'
        }
        break
      case 'MT':
        item.short_name = item.name
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'MT in Progress...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'MT Failed'
        } else {
          item.parsedName = item.name
        }
        break
      case 'HT':
        item.short_name = item.name
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'HT in Progress...'
        } else if (item.status === STEP_STATUS.SKIPPED && !phasesProperties.coreFallback) {
          item.parsedName = 'HT Skipped'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'HT Failed'
        } else if (item.status === STEP_STATUS.CANCELED) {
          item.parsedName = 'HT Canceled'
        } else {
          item.parsedName = item.name
        }
        break
      case 'REVIEW':
        item.short_name = 'S. REVIEW'
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'S. REVIEW in Progress...'
        } else if (item.status === STEP_STATUS.SKIPPED && !phasesProperties.coreFallback) {
          item.parsedName = 'S. REVIEW Skipped'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'S. REVIEW Failed'
        } else if (item.status === STEP_STATUS.CANCELED) {
          item.parsedName = 'S. REVIEW Canceled'
        } else {
          item.parsedName = 'S. Review'
        }
        break
      case 'REBUILD':
        item.short_name = item.name
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'Rebuilding...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'Rebuild Failed'
        } else {
          item.parsedName = item.name.toLowerCase()
        }
        break
      case 'DELIVER':
        item.short_name = item.name
        if (item.status === STEP_STATUS.PENDING) {
          item.parsedName = 'Delivering...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'Deliver Failed'
        } else {
          item.parsedName = item.name.toLowerCase()
        }
        break
      case 'TM_UPDATE':
        item.short_name = 'TM UPDATE'
        if (item.status === STEP_STATUS.ONGOING) {
          item.parsedName = 'Updating TM...'
        } else if (item.status === STEP_STATUS.FAILED) {
          item.parsedName = 'TM Update Failed'
        } else {
          item.parsedName = 'TM Update'
        }
        break
      case 'COMPLETED':
        item.short_name = item.name
        item.parsedName = item.name.toLowerCase()
        break
      default:
        item.short_name = item.name
        item.parsedName = item.name.toLowerCase()
        break
    }

    if (item.started_at) {
      item.parsed_started_at = formatToXrayDateDisplay(item.started_at)
      item.utc_parsed_started_at = formatToXrayDateDisplay(item.started_at, true)
    }

    if (item.completed_at) {
      item.parsed_completed_at = formatToXrayDateDisplay(item.completed_at)
      item.utc_parsed_completed_at = formatToXrayDateDisplay(item.completed_at, true)
    }

    return item
  })
}

export function __assignFlowStatusByPhaseDates (item) {
  let status = ''

  if (!item.started_at) {
    status = STEP_STATUS.INIT
  } else if (item.started_at && !item.completed_at) {
    status = STEP_STATUS.PENDING
  } else if (item.started_at && item.completed_at) {
    status = STEP_STATUS.COMPLETED
  } else {
    console.error('no valid conditions for __assignFlowStatusByPhaseDates function ::: ', item)
  }

  return status
}

function __findMatchingMTNugget (target, stepId, details) {
  const stepExecution = details.trace?.workflow_execution?.step_executions?.find(step => step.step_id === stepId)
  return stepExecution?.output?.data?.mt_response?.translated_data?.nuggets?.[target.position]
}

/* GENERAL MT DETAILS */
export function __handleMachineTranslationGeneralDetails (details) {
  let stepId
  const mtDetails = details.machine_translation_details

  const parsedMTDetails = mtDetails.map((item, index) => {
    const listItem = Object.assign({}, item)

    // only parse if it's an ISO date, otherwise the default on the component will show it as it is (listItem.job_engine_model_version)
    if (`${listItem.job_engine_model_version}`.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d/)) {
      listItem.parsed_job_engine_model_version = formatToXrayDateDisplay(listItem.job_engine_model_version)
      listItem.utc_parsed_job_engine_model_version = formatToXrayDateDisplay(listItem.job_engine_model_version, true)
    }

    // order nuggets by position
    if (item.nuggets && item.nuggets.length) {
      stepId = `${EXECUTION_STEPS.MT}-${index + 1}`

      listItem.nuggets = item.nuggets.sort((a, b) => a.position - b.position).map(nugget => {
        let out = Object.assign({}, nugget)

        const flowrunnerMatch = __findMatchingMTNugget(nugget, stepId, details)
        if (flowrunnerMatch) {
          out = { ...flowrunnerMatch, ...out }
        }

        return out
      })
    }

    // if it's a 2 leg MT, display QE only for the last leg (we only skip HT based on QE of the last MT done)
    if (mtDetails.length > 1 && index === 0) {
      delete listItem.qe_score
      delete listItem.qe_threshold
    }

    return listItem
  })

  return parsedMTDetails
}

/* GENERAL HT DETAILS */
export function __handleHumanTranslationGeneralDetails (translationData) {
  const newHumanTranslationDetails = Object.assign({}, translationData.human_translation_details)
  const currHTData = translationData.human_translation_details

  if (translationData.translation_profile && translationData.translation_profile.content_type) {
    currHTData.content_type = translationData.translation_profile.content_type
  }

  if (currHTData.created_at && currHTData.updated_at) {
    const elapsedDiff = calcDatesDiff(currHTData.created_at, currHTData.updated_at)

    newHumanTranslationDetails.parsed_created_at = elapsedDiff.parsedStartDate
    newHumanTranslationDetails.utc_parsed_created_at = elapsedDiff.utcStartDate
    newHumanTranslationDetails.parsed_updated_at = elapsedDiff.parsedEndDate
    newHumanTranslationDetails.utc_parsed_updated_at = elapsedDiff.utcEndDate
  }

  if (currHTData.created_at && currHTData.completed_at) {
    const spentDiff = calcDatesDiff(currHTData.created_at, currHTData.completed_at)

    newHumanTranslationDetails.parsed_completed_at = spentDiff.parsedEndDate
    newHumanTranslationDetails.utc_parsed_completed_at = spentDiff.utcEndDate
    newHumanTranslationDetails.time_taken_completion = spentDiff.diffElapsed
    newHumanTranslationDetails.full_time_taken_completion = spentDiff.diffFullTime
  }

  if (currHTData.created_at && currHTData.canceled_at) {
    const spentDiff = calcDatesDiff(currHTData.created_at, currHTData.canceled_at)

    newHumanTranslationDetails.parsed_canceled_at = spentDiff.parsedEndDate
    newHumanTranslationDetails.utc_parsed_canceled_at = spentDiff.utcEndDate
    newHumanTranslationDetails.time_taken_cancelation = spentDiff.diffElapsed
    newHumanTranslationDetails.full_time_taken_cancelation = spentDiff.diffFullTime
  }

  // order source nuggets by position
  if (currHTData.content?.source_nuggets?.length) {
    newHumanTranslationDetails.content.source_nuggets = currHTData.content.source_nuggets.sort((a, b) => a.position - b.position)
  }

  // order target nuggets by position
  if (currHTData.content?.target_nuggets?.length) {
    newHumanTranslationDetails.content.target_nuggets = currHTData.content.target_nuggets.sort((a, b) => a.position - b.position)
  }

  return newHumanTranslationDetails
}

/* TASKS AND TRANSLATION RECORDS */
export function __handleTasks (tasksArray, options) {
  const prioritiesArray = []

  const parsedTasksArray = tasksArray.map((task, index) => {
    const taskItem = Object.assign({}, task)
    prioritiesArray.push(task.priority)

    // translate "in_progress" flag to a "human-friendly" state
    taskItem.in_progress ? taskItem.task_status = HT_TASK_STATUS.ENQUEUED : taskItem.task_status = HT_TASK_STATUS.DEQUEUED

    if (task.devices && task.devices.length) {
      taskItem.parsed_devices = task.devices.join(', ')
    }

    if (taskItem.language_pair && taskItem.language_pair.indexOf('_') !== -1) {
      taskItem.source_language = taskItem.language_pair.split('_')[0]
      taskItem.target_language = taskItem.language_pair.split('_')[1]
    }

    // label for humans
    if (task.type) {
      switch (task.type) {
        case TASK_TYPE.MAP_REDUCE_PAID:
          taskItem.parsed_type = 'Paid Task'
          break
        case TASK_TYPE.SEGMENT_CURATION:
          taskItem.parsed_type = 'TM Curation'
          break
        case TASK_TYPE.REVIEW:
          taskItem.parsed_type = 'Senior Editor'
          break
        default:
          taskItem.parsed_type = task.type
          break
      }
    }

    if (task.source_nuggets && task.source_nuggets.length) {
      taskItem.source_nuggets = __parseNuggetsForAnnotations(task.source_nuggets, 'ht', options)
    }

    if (task.target_nuggets && task.target_nuggets.length) {
      taskItem.target_nuggets = __parseNuggetsForAnnotations(task.target_nuggets, 'ht', options)
    }

    // HT TRANSLATION RECORDS
    if (taskItem.translation_records && taskItem.translation_records.length) {
      taskItem.translation_records.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))

      const recordsData = __handleTranslationRecords(taskItem.translation_records, options?.glossaryId)

      taskItem.translation_records = recordsData.records
      taskItem.records_properties = recordsData.records_properties
      taskItem.completed_at = taskItem.translation_records.every(item => item.completed_at && item.completed_at.length) ? taskItem.translation_records[taskItem.translation_records.length - 1].completed_at : null
    }

    taskItem.task_edit_distance = taskItem.records_properties?.task_edit_distance || 0
    taskItem.task_editor_username = taskItem.records_properties?.task_editor_username || null
    taskItem.task_canceled_count = taskItem.records_properties?.task_canceled_count || 0
    taskItem.task_skipped_count = taskItem.records_properties?.task_skipped_count || 0

    taskItem.parsed_created_at = formatToXrayDateDisplay(taskItem.created_at)
    taskItem.utc_parsed_created_at = formatToXrayDateDisplay(taskItem.created_at, true)

    if (taskItem.completed_at) {
      taskItem.parsed_completed_at = formatToXrayDateDisplay(taskItem.completed_at)
      taskItem.utc_parsed_completed_at = formatToXrayDateDisplay(taskItem.completed_at, true)
    }

    if (taskItem.updated_at) {
      taskItem.parsed_updated_at = formatToXrayDateDisplay(taskItem.updated_at)
      taskItem.utc_parsed_updated_at = formatToXrayDateDisplay(taskItem.updated_at, true)
    }

    if (taskItem.deleted_at) {
      taskItem.parsed_deleted_at = formatToXrayDateDisplay(taskItem.deleted_at)
      taskItem.utc_parsed_deleted_at = formatToXrayDateDisplay(taskItem.deleted_at, true)
    }

    taskItem.parsed_timeout_seconds = taskItem.timeout_seconds ? parseDuration(new Date(0), new Date(taskItem.timeout_seconds * 1000)).diffElapsed : taskItem.timeout_seconds

    if (taskItem.created_at && taskItem.completed_at) {
      const calcDates = calcDatesDiff(new Date(taskItem.created_at), new Date(taskItem.completed_at))
      taskItem.time_taken = calcDates.diffElapsed
      taskItem.row_parsed_completed_at = calcDates.parsedEndDate
      taskItem.full_time_taken = calcDates.diffFullTime
      taskItem.time_taken_seconds = calcDates.diffAsSeconds

      taskItem.exceeded_sla = taskItem.timeout_seconds ? taskItem.time_taken_seconds > taskItem.timeout_seconds : false
    } else {
      const calcDates = calcDatesDiff(new Date(taskItem.created_at), new Date())
      taskItem.time_elapsed = calcDates.diffElapsed
      taskItem.full_time_elapsed = calcDates.diffFullTime
      taskItem.time_elapsed_seconds = calcDates.diffAsSeconds

      taskItem.exceeded_sla = taskItem.timeout_seconds ? taskItem.time_elapsed_seconds > taskItem.timeout_seconds : false
    }

    if (task.decrease_expertise_level_at && taskItem.decrease_expertise_level_at.length && taskItem.decrease_expertise_level_at[0]) {
      taskItem.time_to_degredation = calcDatesDiff(new Date(), new Date(taskItem.decrease_expertise_level_at[0])).diffElapsed
    }

    taskItem.isIdCopied = false
    taskItem.isChecked = false

    return taskItem
  })

  return {
    parsedTasksArray,
    maxPriority: Math.max(...prioritiesArray) || undefined
  }
}

export function __generateMarkupLabel (htmlTag) {
  // Remove any leading/trailing whitespace
  htmlTag = htmlTag.trim()

  // Regular expression to match valid opening, closing, and self-closing tags
  const validTagPattern = /^<\/?([a-zA-Z]+)(?:\s[^>]*)?>$/

  // Extract the tag name
  const match = htmlTag.match(validTagPattern)

  // Add evaluate with is a closing or opening a tag
  if (match) {
    if (htmlTag.startsWith('</')) {
      return { tagType: TAG_PURPOSE.CLOSE, value: match[1] }
    } else {
      return { tagType: TAG_PURPOSE.OPEN, value: match[1] }
    }
  }

  // If no match or invalid tag, return null or an empty string
  return null
}

export function __parseMarkupTags (nugget) {
  const { markup } = nugget
  let tagElements = []

  if (markup) {
    const TAG_TYPE = 'tag'
    const TAG_CLASS = 'markup_tag'
    let elIndex
    let blocks = []

    const addToBlock = (id, tagType, value, start) => {
      const similarBlock = blocks.find(item => item.tagType === tagType)
      let label = ''
      let closingCount = 1

      if (tagType === TAG_PURPOSE.CLOSE) {
        label = '/'
        closingCount = -1
      }

      if (similarBlock) {
        similarBlock.idList.push(id)
        similarBlock.valueList.push(value)
        similarBlock.toBeClosed += closingCount
        similarBlock.label = `${label}${similarBlock.idList[0]}-${id}`
        similarBlock.title = 'Multiple tags'
      } else {
        label = `${label}${id}`
        blocks.push({ type: TAG_TYPE, title: 'Markup tag', class: TAG_CLASS, tagType, valueList: [value], start, label, idList: [id], toBeClosed: closingCount })
      }
    }

    const sortTags = (a, b) => {
      // Extract tag names by removing angle brackets
      const tagA = a.text.replace(/[<>]/g, '')
      const tagB = b.text.replace(/[<>]/g, '')

      if (tagA < tagB) return 1
      if (tagA > tagB) return -1
      return 0
    }

    // start by processing the markup data
    Object.keys(markup).forEach(startStr => {
      const tagBlock = markup[startStr]
      const start = +startStr
      blocks = []

      tagBlock.sort(sortTags).forEach(tag => {
        const pastElements = [...tagElements, ...blocks]
        const { value, tagType } = __generateMarkupLabel(tag.text) ?? {}

        if (!value) return

        // start by checking if we are closing a pending tag
        // Example: stack contains `[<a>]` and we are evaluating `</a>`
        elIndex = pastElements.findLastIndex(el => el.valueList.includes(value) && el.toBeClosed > 0 && tagType === TAG_PURPOSE.CLOSE)

        // if element was found, then we need to check how is it closing
        // so we can present a unified block instead of two adjacent blocks if needed
        // Example `<a/>` instead of `<a> </a>`
        if (elIndex >= 0) {
          const el = pastElements[elIndex]
          el.toBeClosed = el.toBeClosed - 1

          if (start === el.start) {
            el.class = el.class.concat(' self_closing')
            el.tagType = TAG_PURPOSE.SELF_CLOSE
          } else {
            const valueIdx = el.valueList.findLastIndex(val => val === value)
            addToBlock(el.idList[valueIdx], tagType, value, start)
          }
        } else {
          // if we didn't encounter, we need to add it to the list of tags
          // start by finding last id
          const lastId = pastElements.reduce((max, tag) => Math.max(tag.idList[tag.idList.length - 1], max), 0)
          const id = lastId + 1

          addToBlock(id, tagType, value, start)
        }
      })

      tagElements = tagElements.concat(blocks)
    })

    const anyOutstanding = tagElements.some(el => el.toBeClosed > 0)

    if (anyOutstanding) {
      tagElements.push({ type: TAG_TYPE, class: `${TAG_CLASS} outstanding`, start: Infinity, title: 'Outstanding tags' })
    }
  }

  return tagElements
}

export function __handleTranslationRecords (translationRecords, options) {
  const recordsProperties = {
    task_canceled_count: 0,
    task_skipped_count: 0,
    task_edit_distance: 0,
    task_editor_username: null
  }

  const newRecords = translationRecords.map(record => {
    const recordItem = Object.assign({}, record)

    // round avg edit distance to 6 decimal places
    if (parseFloat(record.avg_edit_distance) && parseFloat(record.avg_edit_distance) > 0) {
      recordItem.avg_edit_distance = Math.round((parseFloat(record.avg_edit_distance) + Number.EPSILON) * 1000000) / 1000000
    }

    if (record.action === 'submit') {
      recordsProperties.task_edit_distance = recordItem.avg_edit_distance
      recordsProperties.task_editor_username = (record.editor && record.editor.name) || undefined
    }

    if (record.action === 'cancel') {
      recordsProperties.task_canceled_count++
    }

    if (record.action === 'skip') {
      recordsProperties.task_skipped_count++
    }

    if (record.created_at && record.completed_at) {
      const datesDiff = calcDatesDiff(
        record.created_at,
        record.completed_at
      )

      recordItem.parsed_created_at = datesDiff.parsedStartDate
      recordItem.utc_parsed_created_at = datesDiff.utcStartDate
      recordItem.parsed_completed_at = formatToXrayDateDisplay(record.completed_at)
      recordItem.utc_parsed_completed_at = formatToXrayDateDisplay(record.completed_at, true)

      recordItem.parsed_duration = datesDiff.diffElapsed
      recordItem.full_duration = datesDiff.diffFullTime
    } else {
      if (record.created_at) {
        recordItem.parsed_created_at = formatToXrayDateDisplay(record.created_at)
        recordItem.utc_parsed_created_at = formatToXrayDateDisplay(record.created_at, true)
      }

      if (record.completed_at) {
        recordItem.parsed_completed_at = formatToXrayDateDisplay(record.completed_at)
        recordItem.utc_parsed_completed_at = formatToXrayDateDisplay(record.completed_at, true)
      }

      if (record.deleted_at) {
        recordItem.parsed_deleted_at = formatToXrayDateDisplay(record.deleted_at)
        recordItem.utc_parsed_deleted_at = formatToXrayDateDisplay(record.deleted_at, true)
      }

      recordItem.parsed_duration = parseDuration(new Date(), new Date(recordItem.created_at)).diffElapsed
      recordItem.full_duration = parseDuration(new Date(), new Date(recordItem.created_at)).diffFullTime
    }

    if (record.source_nuggets && record.source_nuggets.length) {
      recordItem.source_nuggets = __parseNuggetsForAnnotations(record.source_nuggets, 'ht', options)
    }
    if (record.target_nuggets && record.target_nuggets.length) {
      recordItem.target_nuggets = __parseNuggetsForAnnotations(record.target_nuggets, 'ht', options)
    }

    recordItem.isIdCopied = false

    return recordItem
  })

  return {
    records: newRecords,
    records_properties: recordsProperties
  }
}

/* NUGGETS */
export function __handleNuggets (translationData, options) {
  let source = []
  let mt = []
  const ht = []

  // MT and source nuggets
  if (translationData.machine_translation_details) {
    mt = translationData.machine_translation_details.map((item, index) => {
      if (index === 0 && item.nuggets) {
        source = __parseNuggetsForAnnotations(item.nuggets, 'source', options)
      }

      if (item.nuggets) {
        return __parseNuggetsForAnnotations(item.nuggets, 'mt', options)
      }

      return []
    })
  }

  // HT nuggets
  if (translationData.human_translation_details?.uid?.length && translationData?.human_translation_details?.content?.nuggets) {
    translationData.human_translation_details.content.nuggets.forEach(stepNuggets => {
      ht.push(__parseNuggetsForAnnotations(stepNuggets, 'ht', options))
    })
  }

  const nuggetsData = {
    source,
    mt,
    ht
  }

  // source num of words calculation
  if (nuggetsData.source.length) {
    const initialValue = 0
    nuggetsData.source_number_of_words = nuggetsData.source.reduce((acc, item) => {
      return acc + item.num_words
    }, initialValue)
  }

  // target num of words calculation
  if (translationData.human_translation_details?.created_at && nuggetsData.ht.length) {
    const initialValue = 0
    nuggetsData.target_number_of_words = nuggetsData.ht.reduce((acc, item) => {
      return acc + item.num_words
    }, initialValue)
  } else if (nuggetsData.mt.length) {
    const initialValue = 0
    nuggetsData.target_number_of_words = nuggetsData.mt[nuggetsData.mt.length - 1].reduce((acc, item) => {
      return acc + item.mt_num_words
    }, initialValue)
  } else {
    nuggetsData.target_number_of_words = 0
  }

  // language names per nuggets group (source, MT, HT)
  if (translationData && translationData.translation_profile) {
    const sourceLang = languageOptions[`${translationData.translation_profile?.source_language}`]
    nuggetsData.source_language = (sourceLang && sourceLang.label) || translationData.translation_profile?.source_language

    nuggetsData.mt_languages = []
    const mtLang2 = languageOptions[`${translationData.translation_profile?.target_language}`]

    if (translationData.translation_profile?.pivoted) {
      const mtLang1 = languageOptions[`${translationData.translation_profile?.pivoted_language}`]
      nuggetsData.mt_languages[0] = (mtLang1 && mtLang1.label) || translationData.translation_profile?.pivoted_language
      nuggetsData.mt_languages[1] = (mtLang2 && mtLang2.label) || translationData.translation_profile?.target_language
    } else {
      nuggetsData.mt_languages[0] = (mtLang2 && mtLang2.label) || translationData.translation_profile?.target_language
    }

    if (ht.length > 0) {
      nuggetsData.ht_language = (mtLang2 && mtLang2.label) || translationData.translation_profile?.target_language
    }
  }

  return nuggetsData
}

/* ANNOTATIONS */
export function __parseNuggetsForAnnotations (nuggets, type, options) {
  const parsedNuggets = []
  nuggets.forEach((nugget, index) => {
    parsedNuggets.push(Object.assign({}, nugget))

    if (type === 'source' || type === 'mt') {
      const isSource = type === 'source'
      const TEXT_KEY = isSource ? 'text' : 'mt_text'
      const ANNOTATION_KEY = isSource ? 'text_annotations' : 'mt_annotations'

      if (nugget?.[TEXT_KEY]) {
        if (nugget[ANNOTATION_KEY] && Object.keys(nugget[ANNOTATION_KEY]).length) {
          parsedNuggets[index].parsedText = __handleAnnotations(type, nugget, options)
        } else {
          parsedNuggets[index].parsedText = nugget[TEXT_KEY]
        }
      } else {
        parsedNuggets[index].parsedText = '---'
      }
    } else if (type === 'ht') {
      if (nugget.inProgress) {
        parsedNuggets[index].parsedText = 'Translation in progress...'
      } else if (nugget.hasFailed) {
        parsedNuggets[index].parsedText = 'Error fetching nugget from Tarkin...'
      } else if (nugget.text && nugget.annotations && nugget.annotations.length) {
        parsedNuggets[index].parsedText = __handleAnnotations(type, nugget, options)
      } else if (nugget.text) {
        parsedNuggets[index].parsedText = nugget.text
      } else {
        parsedNuggets[index].parsedText = '---'
      }
    } else {
      parsedNuggets[index].parsedText = ''
    }
  })

  return parsedNuggets
}

export function __handleAnnotations (nuggetType, nugget, options) {
  const { glossaryId, customerId } = options ?? {}
  const ANONYMIZATION_CLASS = 'nugget_highlight_anonymization'
  const GLOSSARY_CLASS = 'nugget_highlight_glossary'
  const NO_TRANSLATE_CLASS = 'nugget_highlight_no_translate'
  const DEFAULT_CLASS = 'nugget_default_annotation'
  let ANNOTATIONS_PROP
  let TEXT_PROP

  // annotations prop
  switch (nuggetType) {
    case 'source':
      ANNOTATIONS_PROP = 'text_annotations'
      break
    case 'mt':
      ANNOTATIONS_PROP = 'mt_annotations'
      break
    case 'ht':
      ANNOTATIONS_PROP = 'annotations'
      break
    default:
      ANNOTATIONS_PROP = 'default_annotations'
      break
  }

  // text prop
  switch (nuggetType) {
    case 'source':
    case 'ht':
      TEXT_PROP = 'text'
      break
    case 'mt':
      TEXT_PROP = 'mt_text'
      break
    default:
      TEXT_PROP = 'default_text'
      break
  }

  let finalNuggetText = nugget[TEXT_PROP]
  const mtAnnotations = []

  // create an array of annotations for each nugget (for SOURCE, MT only)
  if (nuggetType === 'source' || nuggetType === 'mt') {
    Object.keys(nugget[ANNOTATIONS_PROP]).forEach(annotationType => {
      if (Object.keys(nugget[ANNOTATIONS_PROP][annotationType]).length) {
        Object.keys(nugget[ANNOTATIONS_PROP][annotationType]).forEach(individualAnnotation => {
          if (nugget[ANNOTATIONS_PROP][annotationType][individualAnnotation] && Object.keys(nugget[ANNOTATIONS_PROP][annotationType][individualAnnotation]).length) {
            mtAnnotations.push({
              ...nugget[ANNOTATIONS_PROP][annotationType][individualAnnotation],
              type: annotationType
            })
          }
        })
      }
    })
  }

  let annotationsList = nuggetType === 'ht' ? nugget[ANNOTATIONS_PROP] : mtAnnotations

  // add markup tags as annotations
  if (nugget.taskType === 'Revision' && nugget.execution_details?.has_human_handled_markup) {
    const tagElements = __parseMarkupTags(nugget)
    annotationsList = tagElements.concat(annotationsList)
  }

  // if there are no valid annotations or markups just return the original text
  if (!annotationsList.length) {
    return finalNuggetText
  }

  for (let i = 0; i < annotationsList.length; i += 1) {
    const currentHighlight = annotationsList[i]
    const currentHighlightStart = currentHighlight.start
    const currentHighlightEnd = currentHighlight.end ?? currentHighlight.start

    let highlightClass
    let annotationFirstPatch
    let annotationSecondPatch

    switch (currentHighlight.type) {
      case 'tag':
        highlightClass = currentHighlight.class
        annotationFirstPatch = `<span title="${currentHighlight.title}" class="${highlightClass}">`
        annotationSecondPatch = `${currentHighlight.label ?? ''}</span>`
        break
      case 'anonymization':
        highlightClass = ANONYMIZATION_CLASS
        annotationFirstPatch = `<span title="${__generateAnnotationTitle(annotationsList[i], nuggetType)}" class="${highlightClass}">`
        break
      case 'glossary':
        highlightClass = GLOSSARY_CLASS
        annotationFirstPatch = `<a class="${highlightClass}" href="https://${customerId}.unbabel.com/linguistic-resources/glossaries/${glossaryId}/terms" rel="noopener noreferrer" target="_blank" title="Glossary">`
        annotationSecondPatch = '</a>'
        break
      case 'notranslate':
        highlightClass = NO_TRANSLATE_CLASS
        annotationFirstPatch = `<span title="No translate" class="${highlightClass}">`
        break
      default:
        highlightClass = DEFAULT_CLASS
        annotationFirstPatch = `<span title="${__generateAnnotationTitle(annotationsList[i], nuggetType)}" class="${highlightClass}">`
        break
    }

    const firstPatch = annotationFirstPatch || `<span class="${highlightClass}">`
    const secondPatch = annotationSecondPatch || '</span>'

    finalNuggetText = __patchStringAtTwoIndexes(
      finalNuggetText,
      currentHighlightStart,
      currentHighlightEnd,
      firstPatch,
      secondPatch
    )

    /**
     * By adding an HTML element "<span></span>" to the segment's text we
     * are making all indexes in the highlights outdated. We need to
     * loop through all of them and patch their indexes
     */
    for (let j = i + 1; j < annotationsList.length; j += 1) {
      const highlight = annotationsList[j]
      const highlightStart = highlight.start
      const highlightEnd = highlight.end

      if (currentHighlightStart < highlightStart) {
        highlight.start += firstPatch.length
      }

      if (currentHighlightEnd < highlightStart) {
        highlight.start += secondPatch.length
      }

      if (currentHighlightStart < highlightEnd) {
        highlight.end += firstPatch.length
      }

      if (currentHighlightEnd < highlightEnd) {
        highlight.end += secondPatch.length
      }
    }
  }

  return finalNuggetText
}

export function __generateAnnotationTitle (annotation, nuggetType) {
  let title = 'undefined'

  if (nuggetType === 'source' || nuggetType === 'mt') {
    if (annotation.type === 'anonymization') {
      title = annotation.annotation_type ? `Anonymisation\r\n\r\nType: ${annotation.annotation_type}` : ''
    } else {
      title = `${annotation.type.charAt(0).toUpperCase() + annotation.type.slice(1)}`
    }
  } else if (nuggetType === 'ht') {
    if (annotation.type === 'anonymization') {
      const annonType = annotation.anonymization_type ? annotation.anonymization_type : annotation.annotation_type ? annotation.annotation_type : null
      title = annonType ? `Anonymisation\r\n\r\nType: ${annonType.toLowerCase()}` : ''
    } else {
      title = `${annotation.type}`
    }
  }

  return title
}

export function __retrieveOutputXliff (execution) {
  if (!execution?.output?.data) return undefined

  const stepKey = execution.step_id

  if (stepKey.includes(EXECUTION_STEPS.MT)) {
    return execution.output.data.mt_response?.translated_content
  }

  if (stepKey.includes(EXECUTION_STEPS.REBUILD)) {
    return execution.output.data.rebuild_response?.translated_content
  }
}

export function __patchStringAtTwoIndexes (originalString, start, end, firstPatch, secondPatch) {
  const textInbetween = originalString.substring(start, end)
  const newInbetween = `${firstPatch}${textInbetween}${secondPatch}`
  const firstThird = originalString.substr(0, start)
  const lastThird = originalString.substr(end)

  return `${firstThird}${newInbetween}${lastThird}`
}

/* PARSE TRANSLATION */
export function parseTranslationDetails (details) {
  const newDetails = Object.assign({}, details)
  const glossaryId = details?.trace?.workflow_execution?.requested_workflow?.tags?.glossary_id?.[0] || 'MISSING_GLOSSARY_ID'
  const customerId = details.customer?.canonical_name || 'UNKNOWN_CUSTOMER'
  const deadline = details.trace?.workflow_execution?.request_tags?.deadline?.[0]

  newDetails.parsed_created_at = formatToXrayDateDisplay(details.created_at)
  newDetails.parsed_updated_at = formatToXrayDateDisplay(details.updated_at)
  newDetails.parsed_completed = formatToXrayDateDisplay(details.completed)

  if (deadline) {
    newDetails.deadline = deadline
    newDetails.parsedDeadline = formatToXrayDateDisplay(deadline)
    newDetails.parsedDeadlineDiff = parseDuration(new Date(), new Date(deadline))
  }

  if (details.completed) {
    const calcDates = calcDatesDiff(details.created_at, details.completed)
    newDetails.timeSpent = calcDates.diffElapsed
    newDetails.full_timeSpent = calcDates.diffFullTime
  } else {
    const calcDates = calcDatesDiff(details.created_at, details.updated_at)
    newDetails.timeSpent = calcDates.diffElapsed
    newDetails.full_timeSpent = calcDates.diffFullTime
  }

  // GENERAL TRANSLATION data - strip html tags from original content and translation
  if (details.original_content_details && details.original_content_details.text && details.original_content_details.text.length > 0) {
    newDetails.original_content_details.text_stripped = __stripHtmlTags(details.original_content_details.text)
  }

  if (details.delivery_details) {
    if (details.delivery_details.text?.length > 0) {
      newDetails.delivery_details.text_stripped = __stripHtmlTags(details.delivery_details.text)
    }

    if (details.delivery_details.date_time) {
      newDetails.deliveredAt = formatToXrayDateDisplay(details.delivery_details.date_time)
      newDetails.parsedDeliveredAt = formatToXrayDateDisplay(details.delivery_details.date_time)
    }
  }

  // TRACE data
  if (details.trace) {
    const { workflow_execution, executed_phases_data } = details.trace

    // PHASES data - parse phases data for flow details
    if (workflow_execution) {
      const { step_executions, workflow_steps } = workflow_execution

      const phasesProperties = {
        coreFallback: false,
        steps: workflow_steps
      }

      if (details.translation_profile?.architecture === ARCH_STATUS.CORE_FALLBACK) {
        phasesProperties.coreFallback = true
      }

      newDetails.phases = __handlePhasesDataForFlowDiagram(step_executions, phasesProperties)

      // get workflow information from tags
      if (workflow_execution.workflow_tags) {
        details.template_type = workflow_execution.workflow_tags.template_type?.[0]
      }

      // get requested workflow information from tags
      if (workflow_execution?.request_tags?.['x-unbabel-project-id']) {
        newDetails.project_id = workflow_execution.request_tags['x-unbabel-project-id']?.[0]
        newDetails.order_id = workflow_execution.request_tags['x-unbabel-order-id']?.[0]
        newDetails.job_id = workflow_execution.request_tags['x-unbabel-job-id']?.[0]
      }
    } else {
      // DEPRECATED: only here to support v1 translations
      newDetails.phases = __handleV1PhasesData(newDetails.phases, { coreFallback: false })
      newDetails.usingOldPhases = true
    }

    // reorder executed_phases_data keys by started_at dates
    if (executed_phases_data) {
      details.trace.executed_phases_data = executed_phases_data.sort((a, b) => {
        return Date.parse(a[Object.keys(a)[0]].started_at) - Date.parse(b[Object.keys(b)[0]].started_at)
      })
    }
  }

  // HT GENERAL data - dates and nuggets reorder
  if (details && details.human_translation_details) {
    newDetails.human_translation_details = __handleHumanTranslationGeneralDetails(details)
  }

  // HT TASKS data
  if (newDetails && newDetails.human_translation_details?.tasks) {
    const parsedTasks = __handleTasks(newDetails.human_translation_details.tasks, { glossaryId, customerId })
    newDetails.human_translation_details.maxPriority = parsedTasks.maxPriority
    newDetails.human_translation_details.tasks = parsedTasks.parsedTasksArray
  }

  // MT GENERAL data
  if (details && details.machine_translation_details?.length) {
    newDetails.machine_translation_details = __handleMachineTranslationGeneralDetails(details)
  }

  // PARSED NUGGETS information
  if (Object.keys(details).length) {
    newDetails.parsed_nuggets = __handleNuggets(newDetails, { glossaryId, customerId })
  }

  return newDetails
}
